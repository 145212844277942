<template>
	<div style="padding: 20px;  background-color: #fff;">
	<el-collapse v-for="(item,index) in textlist" :key="index">
	  <el-collapse-item :title="item.name" name="1">
	  <p v-html='item.content'></p>
	  </el-collapse-item>

	 </el-collapse>
	</div>
</template>

<script>
  import { joinUs } from "../../../API/footer.js"
	export default {
	
		data() {
			return {
        textlist:[],
			}
		},
		mounted() {
      this.companyIntroduce()
		},
		methods: {
			companyIntroduce() {
			  joinUs().then(res =>{
       
          this.textlist = res.data.data
          console.log( this.textlist);
        })

			},
		}
	}

</script>

<style>
	.itemtitle{
		
		font-size: 15px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 700;
		color: #141414;
		line-height: 26px;
	}
	.dl{
		margin:5px 0;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		line-height: 26px;
	}
</style>
