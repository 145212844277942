<template>
  <div>
    <div
      class="artlist"
      v-for="(item, index) in artlist"
      :key="index"
      @click="showdetail(item.id)"
    >
      <div v-if="index == 0" class="dtitle">{{ title }}</div>
      <h5 style="font-weight:bold;">
        {{ item.title }}
      </h5>
      <div>
        <i>{{ item.modifytime }}</i>
        <p v-html="item.content"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["artlist", "title"],
  methods: {
    showdetail(e) {
      console.log(e);
      this.$router.push({
        path: "/article/detail",
        query: {
          did: e,
        },
      });
    },
  },
};
</script>

<style>
.dtitle {
  position: relative;
  text-align: center;
  height: 55px;
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 55px;
  margin-bottom: 20px;
}
.dtitle::after {
  display: block;
  position: absolute;
  content: "";
  width: 68px;
  height: 3px;
  background: #ff1918;
  border-radius: 0px;
  opacity: 0.42;
  left: 50%;
  margin-left: -34px;
}
.artlist {
  background: #fff;
  border-radius: 4px;
  margin-top: 20px;
  padding: 20px 25px;
  box-sizing: border-box;
  font-family: PingFangSC-Medium, PingFang SC;
  cursor: pointer;
  border-radius: 14px;
}
.artlist h5 {
  font-size: 16px;
  font-weight: 500;
  color: #141414;
  line-height: 22px;
}
.artlist i {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  display: block;
  margin: 10px 0;
}
.artlist p {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
}
</style>