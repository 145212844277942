<template>
	<div style="padding: 20px;  background-color: #fff;">
		<p class="pictitle">服务流程</p>
		<ul class="river">
			<li>
				<img src="../../../assets/image/enter/register.png" alt="">
				<p>注册账号</p>
			</li>
			<li>
				<img src="../../../assets/image/enter/jtleft.png" alt="">
				
			</li>
			<li>
				<img src="../../../assets/image/fwlc2.png" alt="">
				<p>咨询顾问</p>
				</li>
			<li>
				<img src="../../../assets/image/enter/jtleft.png" alt="">
			</li>
			<li>
				<img src="../../../assets/image/fwlc3.png" alt="">
				<p>提供服务</p>
			</li>
			<li>
				<img src="../../../assets/image/enter/jtleft.png" alt="">
			</li>
			<li>
				<img src="../../../assets/image/enter/cg.png" alt="">
				<p>服务完成</p>
			</li>
		</ul>
		
			<p class="dl" v-for="(item,index) in textlist" :key="index" v-html='item.content'></p>
		
		
	</div>
</template>

<script>
  import { service_process } from "../../../API/footer.js"
	export default {
	
		data() {
			return {
        textlist:[],
			}
		},
		mounted() {
      this.companyIntroduce()
		},
		methods: {
			companyIntroduce() {
			  service_process().then(res =>{
       
          this.textlist = res.data.data
          console.log( this.textlist);
        })

			},
		}
	}

</script>

<style>
	.river{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.river li{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.down{
		display: flex;
		justify-content:flex-end
	}
	.pictitle{
		font-weight: 800;
		display: block;
		margin: 20px 0;
	}
	.dl{
		margin:20px 0;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		line-height: 26px;
	}
</style>
