<template>
	<div style="padding: 20px;  background-color: #fff;" >
		<p class="dl" v-for="(item,index) in initData" :key="index" v-html='item.content'>
		</p>	
	
	</div>
</template>

<script>
  import { companyIntroducelist } from "../../../API/footer.js"
	export default {
	
		data() {
			return {
		
        initData:[],
			}
		},
		mounted() {
      this.companyIntroduce()
		},
		methods: {
			companyIntroduce() {
			  companyIntroducelist(1).then(res =>{
          console.log(res);
          this.initData = res.data.data
        })

			},
		}
	}
</script>

<style>
	.dl{
		margin:5px 0;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		line-height: 26px;
	}
</style>
