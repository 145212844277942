<template>
  <div style="background-color: #fff;">
    <div class="toppicd">
      <img src="../../../Newimg/qkruzhu.jpg" alt="" />
    </div>
    <div class="w">
      <div class="enter">
        <mytitle>期刊入驻流程</mytitle>
        <ul class="enter_cont">
          <li v-for="(item,index) in procedure" :key="index">
            <div class="img">
              <img :src="item.src" alt="" />
              <span>
                {{ item.text }}
              </span>
            </div>
            <div class="arrow">
              <img src="../../assets/image/enter/arrow.png" alt="" />
            </div>
          </li>
        </ul>
        <a href="#enter"><div class="application">申请入驻</div></a>
      </div>
    </div>
    <div class="bg">
      <div class="w">
        <div class="advantage">
          <mytitle>平台优势</mytitle>
          <ul>
            <li v-for="item in advantage">
              <div class="aimg">
                <img :src="item.src" alt="" />
              </div>
              <div class="text">
                <span>{{ item.title }}</span>
                <p>{{ item.cont }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="w">
      <div class="apply" id="enter"  name="enter">
        <mytitle>申请入驻</mytitle>
        <div class="myform">
          <div class="myput">
            <input type="text" placeholder="联系姓名" v-model="name" />
            <input type="text" placeholder="联系电话" v-model="phone" />
            <input type="text" placeholder="微信号/QQ" v-model="wxQQ"/>
            <input type="text" placeholder="期刊名称"  v-model="qiname"/>
          </div>
          <div class="btn" @click="shenqingruzhu()">立即申请</div>
        </div>
      </div>
      <div class="coopration">
        <mytitle>合作伙伴</mytitle>
        <div class="cooImg">
          <img v-for="item in coopration" :src="item.src" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Topic from '@/components/Topics.vue'
import mytitle from '@/components/title.vue'
import {
		applyQiKan
	} from "../../API/homepage/policy.js"
export default {
  components: {
    Topic,
    mytitle
  },
  data () {
    return {
      //入驻流程数据
      procedure: [
        // { "src": require('../../assets/image/enter/register.png'), "text": "注册账号" },
        { "src": require('../../assets/image/enter/company.png'), "text": "提交申请" },
        { "src": require('../../assets/image/enter/appli.png'), "text": "身份认证" },
        { "src": require('../../assets/image/enter/sh.png'), "text": "平台审核" },
        { "src": require('../../assets/image/enter/qs.png'), "text": "签署协议" },
        { "src": require('../../assets/image/enter/cg.png'), "text": "入驻成功" }

      ],
      name:'', // 名字
      phone:'',//联系电话
      wxQQ:'', //微信QQ
      qiname:'', // 期刊名称
      //平台优势数据
      advantage: [
        { "src": require("../../assets/image/enter/zg.png"), "title": "平台正规", "cont": "九项互联网经营资质，国家相关部门全程监督，安全可靠" },
        { "src": require("../../assets/image/enter/serve.png"), "title": "八年专业服务团队", "cont": "深耕行业八年，服务客户10万+，瞬间引爆行业价值！" },
        { "src": require("../../assets/image/enter/ws.png"), "title": "数千家期刊合作共赢", "cont": "严格把控论文质量，节约杂志社编辑审稿时间，减轻杂志社负担！" },
        { "src": require("../../assets/image/enter/ai.png"), "title": "互联网+AI+区块链技术", "cont": "打造最智能的投发稿平台、深耕互联网+AI智能+区块链服务商。" }
      ],
      //合作伙伴
      coopration: [
        { "src": require("../../assets/image/enter/h1.png") },
        { "src": require("../../assets/image/enter/h2.png") },
        { "src": require("../../assets/image/enter/h3.png") },
        { "src": require("../../assets/image/enter/h4.png") },
        { "src": require("../../assets/image/enter/h5.png") },
        { "src": require("../../assets/image/enter/h6.png") },
        { "src": require("../../assets/image/enter/h7.png") },
        { "src": require("../../assets/image/enter/h8.png") },
        { "src": require("../../assets/image/enter/h9.png") },
        { "src": require("../../assets/image/enter/h0.png") },
      ]

    }
  },
  mounted () {
  },
  methods:{
    shenqingruzhu(){
      applyQiKan(this.name,this.phone,this.wxQQ,this.qiname).then(res =>{
        if(res.data.code == 0){
           this.$message({
                type: 'info',
                message: '申请成功，稍后工作人员联系您',
                type: 'success'
           })
        }else{
          this.$message(res.data.msg);
        }
      })
    }
  }


}

</script>

<style scoped="scoped">
.apply{
	width: 100% !important;
	padding-bottom: 30px;
	/* background: url(../../../Newimg/beijing.jpg)round; */
}
.enter {
  padding: 10px 0 26px 0;
}
.enter_cont {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  margin-top: 30px;
}
.enter_cont li {
  display: flex;
}

.enter_cont li .arrow img {
  margin:0 26px;
  margin-top: 29px;
  width: 140px;
}
.enter_cont li .img {
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
}
.img img {
  margin-bottom: 14px;
  display: block;
}
.enter_cont li:last-child .arrow {
  display: none;
}
.application {
  cursor: pointer;
  margin: 0 auto;
  margin-top: 30px;
  width: 180px;
  height: 48px;
  background: #ff1918;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  line-height: 48px;
}
.application  a{
	color: #fff;
}
.bg {
  background-color: #f7f7f7;
  padding: 20px;
  padding-bottom: 40px;
}
.advantage ul {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.advantage ul li {
  background-color: #fff;
  margin: 0 10px;
  padding: 20px;
}
.advantage ul li .text span {
  font-size: 18px;
  font-weight: 500;
  color: #141414;
  line-height: 28px;
}
.advantage ul li .text p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}
.myform {
  width: 800px;
  height: 260px;
  /* background: #ffffff; */
  /* box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.1); */
  border-radius: 4px;
  padding: 20px;
  margin: 0 auto;
}
.myput {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.myput input {
  width: 45%;
  height: 60px;
  background: #f7f7f7;
  border-radius: 4px;
  border: 0;
  outline: 0;
  margin: 5px;
  font-size: 18px;
  padding: 10px;
  box-sizing: border-box;
}
.btn {
  width: 50%;
  height: 60px;
  border-radius: 4px;
  margin-left: 25%;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 60px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
  background: #ff1918;
  border-radius: 30px;
}
.coopration {
}
.cooImg {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.cooImg img {
  width: 18%;
  margin: 5px;
  border: 1px #999 dashed;
  object-fit: contain;
}
.toppicd {
  width: 100%;
}
.toppicd img {
  width: 100%;
}
</style>