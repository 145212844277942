<template>
  <div class="content">
    <div class="toppicd">
      <img src="../../../Newimg/chachong.jpg" alt="" />
    </div>
    <div class="test">
      <div class="w test">
        <h3 class="onetitle">在线查重</h3>
        <div class="heng"></div>
        <div class="tetBox">
          <div
            class="item-box"
            v-for="(item, index) in duplicateList"
            :key="index"
          >
            <div class="item">
              <div class="border">
                <img
                  class="icon"
                  src="../../assets/slices/duplicateLogo.jpg"
                  alt=""
                />
              </div>
              <div class="price">
                <span>{{ item.price }}</span
                >元/千字
              </div>
              <div class="title">{{ item.title }}</div>
              <div class="content">
                {{ item.subtitle }}
              </div>
              <div
                class="btn"
                @click="jiance(index)"
                :class="
                  index == 0
                    ? 'color1'
                    : index == 1
                    ? 'color2'
                    : index == 2
                    ? 'color3'
                    : index == 3
                    ? 'color4'
                    : ''
                "
              >
                立即检测
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>登录后使用查重功能</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="gologin">现在去登录</el-button>
      </span>
    </el-dialog>
    <div class="superiority" style="border-top: 94px solid #f7f7f7">
      <h3 class="onetitle">我们的优势</h3>
      <div class="heng"></div>
      <div class="w youshi">
        <div v-for="(item,index) in youshi" :key="index" class="item-box">
          <div class="youshi-item">
            <img :src="item.url" class="image" />
            <div>
              <span>{{ item.title }}</span>
              <div class="content">
                {{ item.con }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="skillIssue" style="border-top: 94px solid #f7f7f7">
      <div class="w">
        <div class="skill">
          <h3 class="onetitle">查重流程</h3>
          <div class="heng"></div>
          <div class="liucheng">
            <img :src="liucheng" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="skillIssue" style="border-top: 94px solid #f7f7f7">
      <div class="w">
        <div class="skill">
          <h3 class="onetitle">我们的技术</h3>
          <div class="heng"></div>
          <div style="display:flex;justify-content: space-between;">
              <div style="width:400px;" v-for="(item,index) in jishu" :key="index">
                <el-card :body-style="{ width: '400px', height: '140px' }">
                  <img :src="item.url" class="image" />
                  <div class="jishu">
                    <span>{{ item.title }}</span>
                    <div class="bottom clearfix">
                      {{ item.con }}
                    </div>
                  </div>
                </el-card>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="skillIssue" style="border-top: 94px solid #f7f7f7">
      <div class="w">
        <div class="issue">
          <h3 class="onetitle">常见问题</h3>
          <div class="heng"></div>
          <div class="questions-box">
            <div
              class="questions-item"
              v-for="(item, index) in question"
              :key="index"
            >
              <div class="questions">
                <span class="questionsactive">Q</span>
                {{ item.problem }}
              </div>
              <div class="answer">
                <span class="questionsactive">A</span>
                <div style="flex: 1" v-html="item.answer"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Topic from "@/components/Topics.vue";
import { problemByType } from "../../API/homepage/problem.js";
export default {
  components: {
    Topic,
  },
  data() {
    return {
      dialogVisible: false,
      duplicateList: [
        {
          price: "5.00",
          title: "期刊论文版",
          subtitle: "期刊论文查重系统适用于期刊发表",
        },
        {
          price: "5.00",
          title: "研究生版",
          subtitle:
            "研究生版论文查重系统，适用于硕士论文、博士论文、研究生论文、MBA论文查重。（对比库含硕博学位论文），检测结果与学校一致。",
        },
        {
          price: "5.00",
          title: "大学生版",
          subtitle:
            "万方大学生版查重系统，支持学生账户提交或多支付渠道检测论文，多版本检测报告呈现多维度检测指标。",
        },
        {
          price: "8.00",
          title: "职称评审版",
          subtitle:
            "已发表版面向申报职称的各类专业技术人员、学生、教师、科研人员等，各省职称评审办公室均认可该检测结果。",
        },
      ],
      liucheng: require("../../assets/slices/liucheng.png"),
      youshi: [
        {
          id: 1,
          url: require("../../assets/slices/d3.png"),
          title: "更快",
          con: "大数据动态指纹比对检测技术，是常规比对速度的 10 倍",
        },
        {
          id: 2,
          url: require("../../assets/slices/d4.png"),
          title: "更准",
          con: "专属对比库。超过 1200 亿海量指纹数据，检测查重效果更精准",
        },
        {
          id: 3,
          url: require("../../assets/slices/d5.png"),
          title: "更安全",
          con: "全程加密处理。基于阿里云 OSS 文档上传报告支持密码加密",
        },
        {
          id: 4,
          url: require("../../assets/slices/d6.png"),
          title: "更省",
          con: "好而不贵 。专业版查重仅 5 元/10000 字",
        },
      ],
      jishu: [
        {
          id: 1,
          url: require("../../assets/slices/d8.png"),
          title: "海量对比库",
          con: "9千万学术期刊和学位论文  超过10亿数量的互联网网页数据",
        },
        {
          id: 2,
          url: require("../../assets/slices/d7.png"),
          title: "优秀算法",
          con: "采用自主研发动态指纹越级扫描技术检测速度快且准确率达99%",
        },
        {
          id: 3,
          url: require("../../assets/slices/d9.png"),
          title: "分布式云计算",
          con: "将需要进行大量计算的项目由多台计算机分别计算",
        },
      ],
      question: [],
      pageNum: 1,
      limit: 10,
    };
  },
  mounted(){
    this.getQuestion();
  },
  methods: {
    async getQuestion() {
      // 1.智能投稿常见问题2.顾问服务常见问题3.平台入驻常见问题4.关于我们常见问题
      problemByType(this.pageNum, this.limit, 5).then((res) => {
        this.question = res.data.data;
      });
    },
    gologin() {
				this.dialogVisible = false
				this.$router.push('/login')
			},
    jiance(index) {
      // 0 期刊论文版  1 研究生版  2 大学生版  3职称评审版
      if (sessionStorage.getItem("sid")) {
        this.dialogVisible = false;
        let identity = sessionStorage.getItem("identity");
        console.log("a_id",identity);
        if(identity == 1){
          if(index != 0 ){
            this.$message('暂时未开通，如有需求请联系客服人员');
          }
        }else{
						this.$message('暂时未开通，如有需求请联系客服人员');
        }
      } else {
        this.dialogVisible = true;
      }
    },
  },
};
</script>

<style>
.dupBanner {
  width: 100%;
  height: 420px;
  background: url("../../assets/slices/banner.png");
  text-align: center;
}

.dupBanner h3 {
  width: 456px;
  height: 92px;
  font-size: 70px;
  font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
  font-weight: bold;
  color: #ffffff;
  line-height: 92px;
  letter-spacing: 6px;
  margin: 0 auto;
  padding: 150px 0 0;
}

.dupBanner div {
  width: 320px;
  height: 50px;
  background: #ffffff;
  border-radius: 25px;
  margin: 50px auto 0;
  font-size: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3100cb;
  line-height: 50px;
  letter-spacing: 2px;
}

.test {
  background-color: #fff;
  text-align: center;
  padding: 10px 0;
  padding-bottom: 40px;
}

.onetitle {
  margin: 0 auto;
  width: 150px;
  height: 42px;
  font-size: 30px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 42px;
  text-align: center;
}

.heng {
  margin: 10px auto 59px;
  width: 68px;
  height: 3px;
  background: #ff1918;
  border-radius: 0px;
  opacity: 0.42;
}

.test .tetBox {
  display: flex;
  width: 100%;
}

.tetBox .item-box {
  width: 25%;
  box-sizing: border-box;
  padding: 0 20px;
}
.tetBox .item-box .item {
  width: 100%;
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.1);
  height: 340px;
  box-sizing: border-box;
  padding: 18px 14px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.tetBox .item-box .item .border {
  font-size: 24px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #7c7c7c;
}
.tetBox .item-box .item .border .icon {
  width: 210px;
  margin: 0 10px;
}
.tetBox .item-box .item .price {
  margin-top: 16px;
  font-size: 16px;
  color: #272727;
}
.tetBox .item-box .item .price span {
  font-size: 34px;
  margin-right: 4px;
  font-weight: bold;
}
.tetBox .item-box .item .title {
  font-size: 18px;
  font-weight: bold;
  margin-top: 16px;
  color: #272727;
}
.tetBox .item-box .item .content {
  font-size: 12px;
  margin-top: 18px;
  background: none;
  line-height: 20px;
  color: #757575;
}
.tetBox .item-box .item:hover .btn {
  height: 80px;
}
.tetBox .item-box .item:hover .color1 {
  background: #6e9de9;
}
.tetBox .item-box .item:hover .color2 {
  background: #fe7209;
}
.tetBox .item-box .item:hover .color3 {
  background: #cd22ff;
}
.tetBox .item-box .item:hover .color4 {
  background: #12def0;
}
.tetBox .item-box .item .btn {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  position: absolute;
  transition: all 0.2s;
  bottom: 0;
}
.color1 {
  background: linear-gradient(to bottom, #bad4ff, #6e9de9);
}
.color2 {
  background: linear-gradient(to bottom, #f9cba7, #fe7209);
}
.color3 {
  background: linear-gradient(to bottom, #ebb0fd, #cd22ff);
}
.color4 {
  background: linear-gradient(to bottom, #aff8ff, #12def0);
}
.superiority {
  padding: 40px 0;
  text-align: center;
  background: #fff;
  padding-bottom: 50px;
}

.superiority img {
  margin-bottom: 24px;
}

.superiority span {
  width: 100%;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 28px;
  text-align: center;
}

.superiority .content {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin-top: 30px;
}
.test .tetBox {
  display: flex;
  width: 100%;
}

.youshi {
  display: flex;
  width: 80%;
}
.youshi .item-box {
  flex: 1;
  box-sizing: border-box;
  padding: 0 20px;
}
.youshi .youshi-item {
  width: 100%;
  box-shadow: 0 0 20px rgba(100, 100, 100, 0.1);
  height: 240px;
  box-sizing: border-box;
  padding: 30px 20px;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
}
.youshi .youshi-item:hover {
  box-shadow: 0 0 40px rgba(100, 100, 100, 0.1);
}
.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
}

.el-card {
  border: none;
  text-align: center;
}

.skillIssue {
  background-color: #fff;
  padding: 20px 0;
  padding-bottom: 40px;
}

.skill .el-card__body {
  display: flex;
}

.skill img {
  width: 140px;
  height: 140px;
}

.skill .jishu {
  padding: 30px;
}

.skill span {
  width: 80px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 22px;
}

.skill .bottom {
  width: 200px;
  height: 48px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  margin: 10px 0 0;
}

.skill .el-card {
  text-align: left;
}

.issue {
  padding: 50px 0 0;
}

.list {
  padding: 30px;
  background: #f7f7f7;
  border-radius: 4px;
  margin-bottom: 20px;
}

.list div {
  display: flex;
}

.list div img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-bottom: 20px;
}

.list div h3 {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 22px;
}

.list div p {
  width: 1190px;
  height: 52px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}
.questions-box .questions-item {
  height: 180px;
  box-sizing: border-box;
  padding-top: 50px;
  border-bottom: 1px solid #e5e5e5;
}

.questions-box {
  width: 100%;
  background: #f7f7f7;
  padding: 0 26px;
  padding-bottom: 80px;
  margin-top: 54px;
  border-radius: 14px;
}
.questions-box .questions-item .questionsactive {
  width: 20px;
  height: 20px;
  color: #fff;
  background: #1082ff;
  display: inline-flex;
  font-size: 12px;
  align-items: center;
  margin-right: 16px;
  justify-content: center;
}
.questions {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #1082ff;
}
.answer {
  display: flex;
  margin-top: 26px;
  font-size: 14px;
  line-height: 20px;
  color: #606060;
}
.liucheng {
  width: 100%;
}
.liucheng img {
  width: 100%;
  height: auto;
}
.content {
  background: #fff;
}
.toppicd {
  width: 100%;
}
.toppicd img {
  width: 100%;
}
</style>
