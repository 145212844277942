<template>
  <div class="content">
    <!-- <div class="header">公司动态</div> -->
    <div class="toppicd">
      <img src="../../../Newimg/newlist.jpg" alt="">
    </div>
    <div class="pageBody">
      <div class="yearlist">
        <el-form ref="form" :model="form" label-width="90px">
          <el-form-item label="筛选时间：">
            <el-select
              v-model="form.year"
              placeholder="请选择日期"
              @change="listCompanyDynamic"
            >
              <el-option label="全部" value=""> </el-option>
              <el-option
                v-for="item in yearlist"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="topnew" v-if="mainNews.id" @click="godetail(mainNews.id)">
        <div class="left">
          <img :src="url + mainNews.pictureUrl" />
        </div>
        <div class="right">
          <img :src="topnews" alt="" />
          <div class="data">
            <el-tooltip
              class="item"
              effect="light"
              :content="mainNews.title"
              placement="top-start"
            >
              <div class="title">{{ mainNews.title }}</div>
            </el-tooltip>
            <!-- <div class="conent" v-html="item.content"></div> -->
            <div class="time">{{ mainNews.createTime }}</div>
            <div class="options">
              查看详情<i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="cpnnewslist">
        <div
          class="item"
          v-for="(item, index) in list"
          :key="index"
          v-if="item.isType == 0"
          @click="godetail(item.id)"
        >
          <img :src="url + item.pictureUrl" alt="" />
          <div class="data">
             <el-tooltip
              class="item"
              effect="light"
              :content="item.title"
              placement="top-start"
            >
              <div class="title">{{ item.title }}</div>
            </el-tooltip>
            <!-- <div class="conent" v-html="item.content"></div> -->
            <div class="bototm">
              <div class="time">{{ item.create_time }}</div>
              <div class="options">
                查看详情<i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="orderPage">
        <el-pagination
          class="fy"
          small
          @size-change="handleSizeChange"
          :page-size="pagesize"
          :page-sizes="[9, 12, 15]"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="current_change"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  yearList,
  listCompanyDynamic,
  listCompanyDynamicMain,
} from "@/API/footer.js";
export default {
  data() {
    return {
      yearlist: [],
      topnews: require("../../assets/image/marketing/topnews.png"),
      url: "", // 图片地址

      form: {
        year: "",
      },
      list: [],
      mainNews: {},
      total: 0, //默认数据总数
      pagesize: 9, //每页的数据条数
      currentPage: 1, //默认开始页面
    };
  },
  created() {
    this.yearList();
    this.listCompanyDynamic();
    this.getMainNews();
    this.url = this.URL;
  },
  watch: {},
  mounted() {},
  methods: {
    godetail(e) {
      this.$router.push("/newsDetail?id=" + e);
    },
    getMainNews() {
      var that = this;
      listCompanyDynamicMain(10, 1, "", 1).then((res) => {
        if (res.data.code == 0) {
          that.mainNews = res.data.data[0];
        }
      });
    },
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.listCompanyDynamic();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pagesize = val;
      this.listCompanyDynamic();
    },
    listCompanyDynamic() {
      var that = this;
      listCompanyDynamic(that.pagesize, that.currentPage, that.form.year).then(
        (res) => {
          if (res.data.code == 0) {
            that.list = res.data.data;
            that.total = res.data.total;
            for (let i = 0; i < this.list.length; i++) {
              that.list[i].content = that.list[i].content.replace(
                /\/static/g,
                "http://www.houtai.kandashi.com.cn/static"
              );
            }
          }
        }
      );
    },
    yearList() {
      yearList().then((res) => {
        if (res.data.code == 0) {
          var datas = res.data.data;
          this.yearlist = [];
          for (var i in datas) {
            this.yearlist.push(Object.values(datas[i])[0]);
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.topnew {
  width: 1200px;
  height: 290px;
  display: flex;
  margin-top: 30px;
  margin-bottom: 70px;
}
.cpnnewslist {
  width: 1200px;
  box-sizing: border-box;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cpnnewslist .item {
  width: 380px;
  background: #fff;
  margin-bottom: 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.cpnnewslist .item .data {
  width: 100%;
  box-sizing: border-box;
  padding: 22px 12px;
}
.cpnnewslist .item .data .title {
  font-size: 16px;
  font-weight: bold;
  color: #7c7c7c;
  text-overflow: ellipsis;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  align-content: center;
  line-height: 30px;
  height: 60px;
}
.cpnnewslist .item .data .conent {
  font-size: 14px;
  line-height: 20px;
  margin-top: 20px;
  color: #7c7c7c;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.cpnnewslist .item .data .bototm {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}
.cpnnewslist .item .data .bototm .time {
  font-size: 14px;
  color: #7c7c7c;
}
.cpnnewslist .item .data .bototm .options {
  width: 100px;
  height: 30px;
  border: 1px solid #7c7c7c;
  color: #7c7c7c;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}
.cpnnewslist .item .data .bototm .options:hover {
  border: 1px solid #00b7ee;
  color: #00b7ee;
}
.cpnnewslist .item img {
  width: 100%;
  height: 200px;
}
.cpnnewslist .item:last-child:nth-child(3n - 1) {
  margin-right: calc(32% + 20px);
}
.topnew .left {
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  padding-left: 20px;
  position: relative;
}
.topnew .left img {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
}
.topnew .right {
  flex: 1;
  position: relative;
  height: 100%;
  padding-right: 20px;
  background: #fff;
  margin-left: 20px;
}
.topnew .right .data {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  padding-left: 38px;
}
.topnew .right .data .title {
  z-index: 2;
  font-size: 18px;
  color: #565656;
  font-weight: bold;
}
.topnew .right .data .conent {
  position: relative;
  font-size: 14px;
  color: #7c7c7c;
  margin-top: 22px;
  line-height: 20px;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.topnew .right .data .time {
  font-size: 14px;
  color: #7c7c7c;
  margin-top: 24px;
}
.topnew .right .data .options {
  width: 100px;
  height: 30px;
  border: 1px solid #7c7c7c;
  color: #7c7c7c;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
}
.topnew .right .data .options:hover {
  border: 1px solid #00b7ee;
  color: #00b7ee;
}

.topnew .right img {
  height: 100%;
  position: absolute;
  margin-left: -20px;
}
.pageBody {
  width: 1200px;
  margin: 0 auto;
}

.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  background: #00b7ee;
  color: #fff;
  font-size: 70px;
  font-weight: bold;
  letter-spacing: 40px;
}
.content {
  background: #f7f7f7;
}
.orderPage {
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.yearlist {
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
}
.toppicd{
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
}
.toppicd img{
  width: 100%;
}
</style>
