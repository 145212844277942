<template>
  <div>
    <Topic>
      <!-- <div slot="topicTitle" class="first">优质期刊投稿</div>
      <div slot="topicSqu" class="last">全网数字化大数据</div> -->
    </Topic>
    <div class="bg2" id="questions">
      <div class="w">
        <div class="enter">
          <mytitle>常见问题</mytitle>
          <div class="questions-box">
            <div class="questions-item" v-for="(item,index) in problemList" :key="index">
              <div class="questions">
                <span class="questionsactive">Q</span>
                {{item.problem}}
              </div>
              <div class="answer">
                <span class="questionsactive">A</span>
                <div style="flex: 1" v-html="item.answer">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Topic from "@/components/Topics.vue";
import mytitle from "@/components/title.vue";
import { problemByType } from "@/API/homepage/problem.js";
export default {
  components: {
    Topic,
    mytitle,
  },
  data() {
    return {
      pageNum: 1,
      limit: 10,
      
      problemList:[],
      //合作伙伴
      coopration: [
        {
          src: require("@/assets/image/enter/h1.png"),
        },
        {
          src: require("@/assets/image/enter/h2.png"),
        },
        {
          src: require("@/assets/image/enter/h3.png"),
        },
        {
          src: require("@/assets/image/enter/h4.png"),
        },
        {
          src: require("@/assets/image/enter/h5.png"),
        },
        {
          src: require("@/assets/image/enter/h6.png"),
        },
        {
          src: require("@/assets/image/enter/h7.png"),
        },
        {
          src: require("@/assets/image/enter/h8.png"),
        },
        {
          src: require("@/assets/image/enter/h9.png"),
        },
        {
          src: require("@/assets/image/enter/h0.png"),
        },
      ],
    };
  },
  created() {
    if (this.$route.query.id) {
        this.now = this.$route.query.id;
        setTimeout(() => {
          var element = document.getElementById(this.$route.query.id);
          console.log(element, this.$route.query.id);
          if (element) {
            element.scrollIntoView();
          }
        }, 200);
      }
    this.getQuestion();
  },
  watch: {
    $route(to, from) {
      console.log("监听到路由变动了", to.path);
      if (this.$route.query.id) {
        this.now = this.$route.query.id;
        setTimeout(() => {
          var element = document.getElementById(this.$route.query.id);
          console.log(element, this.$route.query.id);
          if (element) {
            element.scrollIntoView();
          }
        }, 200);
      }
    },
  },
  mounted() {},
  methods: {
    async getQuestion() {
      // 1.智能投稿常见问题2.顾问服务常见问题3.平台入驻常见问题4.关于我们常见问题
       problemByType(this.pageNum, this.limit, 3).then((res) => {
        this.problemList = res.data.data;
      });
    },
    skip() {
      if (localStorage.getItem("code")) {
        if (localStorage.getItem("code") === "0") {
          this.$router.push("/Pending/contribute");
        } else {
          this.$alert("请注册成作者在进行投稿！", "", {
            confirmButtonText: "确定",
            callback: (action) => {
              // this.$router.push("/login");
            },
          });
        }
      } else {
        this.$confirm("您还不是作者,请注册成作者在进行投稿?", "提示", {
          cancelButtonText: "取消",
          confirmButtonText: "确定",
          type: "warning",
        })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
  },
};
</script>

<style>
.questions-box {
  width: 100%;
  background: #f7f7f7;
  padding: 0 26px;
  padding-bottom: 80px;
  margin-top: 54px;
}
.questions-box .questions-item {
  height: 180px;
  box-sizing: border-box;
  padding-top: 50px;
  border-bottom: 1px solid #e5e5e5;
}
.questions-box .questions-item .questionsactive {
  width: 20px;
  height: 20px;
  color: #fff;
  background: #1082ff;
  display: inline-flex;
  font-size: 12px;
  align-items: center;
  margin-right: 16px;
  justify-content: center;
}
.questions {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #1082ff;
}
.answer {
  display: flex;
  margin-top: 26px;
  font-size: 14px;
  line-height: 20px;
  color: #606060;
}
.enter {
  padding: 10px 0 26px 0;
}

.liuchengtu {
  width: 100%;
  height: auto;

  background-color: #ffffff;
}

.enter_cont {
  display: flex;
  justify-content: space-between;
}

.enter_cont li {
  display: flex;
}

.enter_cont li .arrow img {
  margin-left: 24px;
  margin-top: 25px;
}

.img {
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.img img {
  width: 80px;
  height: 76px;
  margin-bottom: 8px;
  display: block;
}

.spans {
  width: 100px;
  text-align: center;
}

.enter_cont li:last-child .arrow {
  display: none;
}

.application {
  cursor: pointer;
  margin: 0 auto;
  margin-top: 30px;
  width: 180px;
  height: 48px;
  background: #ff1918;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  line-height: 48px;
}

.bg {
  background-color: #f7f7f7;
  padding: 20px;
  padding-bottom: 40px;
}
.bg2 {
  padding: 20px;
  padding-bottom: 40px;
  background: #fff;
}
.advantage ul {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.advantage ul li {
  background-color: #fff;
  margin: 0 10px;
  padding: 20px;
}

.advantage ul li .text span {
  font-size: 18px;
  font-weight: 500;
  color: #141414;
  line-height: 28px;
}

.advantage ul li .text p {
  margin-top: 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

.myform {
  width: 800px;
  height: 260px;
  background: #ffffff;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px;
  margin: 0 auto;
}

.myput {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.myput input {
  width: 45%;
  height: 60px;
  background: #f7f7f7;
  border-radius: 4px;
  border: 0;
  outline: 0;
  margin: 5px;
  font-size: 18px;
  padding: 10px;
  box-sizing: border-box;
}

.btn {
  width: 100%;
  height: 60px;
  background: #ff1918;
  border-radius: 4px;
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 60px;
  text-align: center;
  margin-top: 20px;
  cursor: pointer;
}

.cooImg {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.cooImg img {
  width: 18%;
  margin: 5px;
  border: 1px #999 dashed;
  object-fit: contain;
}

.dl {
  margin: 5px 0;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}

.ptyscontent {
  display: flex;
}
.baozhang {
  width: 50%;
}
.baozhang img {
  width: 100%;
}
.baozhang2 {
  flex: 1.5;
}
.baozhang2 img {
  width: 100%;
}
.ptyscontent ul {
  flex: 1;
  margin: 20px;
}

.ptyscontent ul li {
  display: flex;
  height: 33.33%;
}

.ptyscontent ul li .item-content {
  flex: 1;
  margin: 0 10px;
}

.ptyscontent ul li div img {
  width: 80px;
  height: 80px;
  margin-right: 16px;
}

.ptyscontent ul li div b {
  display: inline-block;
  margin-bottom: 10px;
}

.conent {
  width: auto;
  height: 75px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
}

.ptyscontents {
  display: flex;
  align-items: center;
}

.ptyscontents ul {
  flex: 1;
}

.ptyscontents ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 100px;
  border-radius: 5px;
}

.ptyscontents ul li div {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ptyscontents ul li div img {
  width: 80px;
  height: 80px;
  margin-bottom: 40px;
}

.ptyscontents ul li div b {
  display: inline-block;
  margin-bottom: 10px;
}

.btns {
  position: relative;
  top: 10px;
  width: 100px;
  height: 36px;
  border-radius: 18px;
  display: flex;
  color: #fff;
  align-items: center;
  justify-content: center;
  background-color: #1082ff;
}
</style>
