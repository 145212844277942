<template>
  <div class="content">
    <!-- <div class="header">公司动态</div> -->
    <div class="toppicd">
      <img src="../../../Newimg/newlist.jpg" alt="">
    </div>
    <div class="document-body">
      <div class="document">
        <div class="title">{{ showdata.title }}</div>
        <div class="time">
          <i class="el-icon-time"></i> {{ showdata.createTime }}
        </div>
        <div class="c-conent">
          <div class="text" v-html="showdata.content"></div>
        </div>
      </div>
      <div class="document-right">
        <div class="document-options">
          <div class="options-item" @click="gotougao">
            <img :src="require('@/assets/image/tougao.png')" alt="" />
          </div>
          <div class="options-item" @click="goqikan">
            <img :src="require('@/assets/image/ruzhu.png')" alt="" />
          </div>
        </div>
        <div class="document-ad">
          <el-carousel height="150px" arrow="never">
            <el-carousel-item
                  v-for="(item, index) in bannerrList"
                  :key="index"
                >
                  <img
                    :src="url + item.cover"
                    @click="golink(item.link)"
                    alt=""
                    class="carousel-img"
                  />
                </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { detailCompanyDynamic } from "@/API/footer.js";
import { Carousel } from "@/API/homepage/policy.js";
export default {
  data() {
    return {
      id: 0,
      url: "",
      showdata: [],
      bannerrList: [],
    };
  },
  created() {},
  watch: {},
  mounted() {
    this.id = this.$route.query.id;
    this.detailCompanyDynamic();
    this.url = this.URL;
    this.CarouseList();
  },
  methods: {
    golink(url) {
      if(url == ''){
        return;
      }
      window.location.href = url;
    },
    CarouseList() {
      Carousel(2).then((res) => {
        this.bannerrList = res.data.data;
      });
    },
    gotougao() {
      this.$router.push("/Pending/contribute");
    },
    goqikan() {
      this.$router.push("/enter?now=2");
    },
    detailCompanyDynamic() {
      var that = this;
      detailCompanyDynamic(this.id).then((res) => {
        console.log("detailCompanyDynamic");
        console.log(res);
        if (res.data.code == 0) {
          this.showdata = res.data.data;
          this.showdata.content = this.showdata.content.replace(
            /\/static/g,
            "http://www.houtai.kandashi.com.cn/static"
          );
        }
      });
    },
  },
};
</script>

<style scoped>
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  background: #00b7ee;
  color: #fff;
  font-size: 70px;
  font-weight: bold;
  letter-spacing: 40px;
}
.content {
  background: #f7f7f7;
}
.orderPage {
  height: 73px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.document-body {
  display: flex;
  width: 80%;
  margin: 30px auto;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 30px 0;
  overflow: hidden;
  position: relative;
}
.document {
  width: 74%;
  left: 0;
  box-sizing: border-box;
  background: #fff;
  padding: 30px;
  border-radius: 16px;
}
.document .title {
  box-sizing: border-box;
  padding-left: 50px;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 50px;
  width: 100%;
}
.document .c-conent {
  box-sizing: border-box;
  padding: 0 24px;
  margin-top: 26px;
}
.document .c-conent img {
  box-sizing: border-box;
  padding: 0 24px;
}
.document .c-conent .text {
  text-indent: 2em;
  color: #666;
  font-size: 16px;
}
.document .time {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 50px;
  font-size: 14px;
  color: #7c7c7c;
  box-sizing: border-box;
  padding-bottom: 30px;
  border-bottom: 2px solid #ddd;
}
.document-right {
  width: 24%;
}
.document-right .document-options .options-item img {
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
}
.document-ad {
  background: #e5e5e5;
  border-radius: 16px;
  margin-top: 20px;
}
.document-hot .document-hot-list {
  margin-top: 16px;
}
.document-hot .document-hot-list .document-hot-item {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  padding-top: 16px;
}
.small {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-img {
  width: 100%;
  height: 100%;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
}
.toppicd{
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
}
.toppicd img{
  width: 100%;
}
</style>
