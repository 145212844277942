<template>
    <div class="app">
        <div class="box box1">
            <div style="display: flex">
                <div class="sorttitile">学术分类</div>
                <el-collapse accordion v-model="activeNames1">
                    <el-collapse-item name="1">
                        <template slot="title">
                            <div class="qkfl">
                <span
                        @click="typebx"
                        :class="xstype == '' ? 'active' : ''"
                >不限</span
                >
                                <span
                                        v-for="(item, index) in type.slice(0, 15)"
                                        :class="xstype == item.id ? 'active' : ''"
                                        :key="index"
                                        @click="selecttype(item.id)"
                                >{{ item.types }}</span
                                >
                            </div>
                        </template>
                        <div class="qkfl">
              <span
                      v-for="(item, index) in type.slice(15, type.length)"
                      :class="xstype == item.id ? 'active' : ''"
                      :key="index"
                      @click="selecttype(item.id)"
              >{{ item.types }}</span
              >
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
            <div style="display: flex">
                <div class="sorttitile">期刊收录</div>
                <el-collapse accordion v-model="activeNames2">
                    <el-collapse-item name="2">
                        <template slot="title">
                            <div class="qkfl">
                <span
                        @click="inclucebx"
                        :class="qkinclude == '' ? 'active' : ''"
                >不限</span
                >
                                <span
                                        v-for="(item, index) in include.slice(0, 8)"
                                        @click="selectinclude(item.id, item.number)"
                                        :class="qkinclude == item.id ? 'active' : ''"
                                        :key="index"
                                >{{ item.include }}</span
                                >
                            </div>
                        </template>
                        <div class="qkfl">
              <span
                      v-for="(item, index) in include.slice(8, include.length)"
                      @click="selectinclude(item.id, item.number)"
                      :class="qkinclude == item.id ? 'active' : ''"
                      :key="index"
              >{{ item.include }}</span
              >
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
            <div style="display: flex">
                <div class="sorttitile">发行周期</div>
                <el-collapse accordion>
                    <el-collapse-item>
                        <template slot="title">
                            <div class="qkfl">
                <span @click="cyclebx" :class="fxcycle == '' ? 'active' : ''"
                >不限</span>
                                <span v-for="(item, index) in publishcycle" :key="index"
                                        :class="fxcycle == item ? 'active' : ''"
                                        @click="selectcycle(item, index)"
                                >{{ item }}</span>
                            </div>
                        </template>
                    </el-collapse-item>
                </el-collapse>
            </div>
            <div style="display: flex">
                <div class="sorttitile">出版地区</div>
                <el-collapse accordion v-model="activeNames3">
                    <el-collapse-item name="3">
                        <template slot="title">
                            <div class="qkfl">
                <span @click="areabx()" :class="cbarea == '' ? 'active' : ''"
                >不限</span>
                                <span v-for="(item, index) in publishing.slice(0, 18)" :key="index"
                                        :class="cbarea == item.area ? 'active' : ''"
                                        @click="selectarea(item.area, item.id)" >{{ item.area }}</span>
                            </div>
                        </template>
                        <div class="qkfl">
              <span
                      v-for="(item, index) in publishing.slice(18, publishing.length)"
                      :class="cbarea == item.area ? 'active' : ''"
                      @click="selectarea(item.area, item.id)"
                      :key="index"
              >{{ item.area }}</span
              >
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>
            <div style="display: flex">
                <div class="sorttitile">审校时间</div>
                <el-collapse accordion>
                    <el-collapse-item>
                        <template slot="title">
                            <div class="qkfl">
                <span @click="timebx()" :class="xstime == '' ? 'active' : ''"
                >不限</span
                >
                                <span
                                        v-for="(item, index) in checktime"
                                        :class="xstime == item ? 'active' : ''"
                                        @click="selecttime(item, index)"
                                        :key="index"
                                >{{ item }}</span
                                >
                            </div>
                        </template>
                    </el-collapse-item>
                </el-collapse>
            </div>
            <div style="display: flex">
                <!--    <div class="sorttitile">影响因子</div>
                    <el-collapse accordion>
                      <el-collapse-item>
                        <template slot="title">
                          <div class="qkfl">
                            <span @click="timebxs()" :class="yxnum == '' ? 'active' : ''"
                              >不限</span
                            >
                            <span
                              v-for="(item, index) in listdatalist"
                              :class="yxnum == item.num ? 'active' : ''"
                              @click="selecttimes(item.num, index)"
                              :key="index"
                              >{{ item.num }}</span
                            >
                          </div>
                        </template>
                      </el-collapse-item>
                    </el-collapse>-->
            </div>
            <div class="choose">
                <div @click="baseontime" :class="baseon == 0 ? 'baseon' : ''">
                    按时间
                </div>
                <div @click="baseoncomment" :class="baseon == 1 ? 'baseon' : ''">
                    按评论
                </div>
                <div @click="baseonpop" :class="baseon == 2 ? 'baseon' : ''">
                    按人气
                </div>
            </div>
            <div class="bg">
                <div class="mcont">
                    <div class="magpic">
                        <div
                                v-for="(item, index) in magzine"
                                :key="index"
                                @click="showmagzinedetail(item.qikan_id, item.qikantype_id)"
                        >
                            <img :src="`https://www.gckerui.com` + item.cover" alt=""/>
                            <span>{{ item.qk_name }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="page">
                <el-pagination
                        class="fy"
                        small
                        @size-change="handleSizeChange"
                        :page-size="pagesize"
                        :page-sizes="[10, 20, 30]"
                        layout="total, sizes, prev, pager, next, jumper"
                        @current-change="current_change"
                        :total="total"
                        background
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        Academic,
        Include,
        Classification,
    } from "../../API/homepage/document/document.js";
    import {searchdoc} from "../../API/homepage/policy.js";
    import preventBack from 'vue-prevent-browser-back';

    export default {


        data() {
            return {
                activeNames1: ["0"],
                activeNames2: ["0"],
                activeNames3: ["0"],
                total: 0, //默认数据总数
                pagesize: 10, //每页的数据条数
                currentPage: 1, //默认开始页面
                type: [], //学术分类
                include: [], //收录
                // 影响因子
                listdatalist: [
                    {
                        num: "0-0.5",
                        id: "1",
                    },
                    {
                        num: "0.5-1.0",
                        id: "2",
                    },
                    {
                        num: "1.0-2.0",
                        id: "3",
                    },
                    {
                        num: "2.1-3.0",
                        id: "4",
                    },
                    {
                        num: "3.1-4.0",
                        id: "5",
                    },
                    {
                        num: "3.1-4.0",
                        id: "6",
                    },
                    {
                        num: "4.1-5.0",
                        id: "5",
                    },
                    {
                        num: "5.1-6.0",
                        id: "6",
                    },
                    {
                        num: "6.1-7.0",
                        id: "7",
                    },
                    {
                        num: "7.1-8.0",
                        id: "8",
                    },
                    {
                        num: "8.1-9.0",
                        id: "9",
                    },
                    {
                        num: "9.0-9.9",
                        id: "10",
                    },
                ],
                publishing: [
                    {
                        area: "天津",
                        id: 1,
                    },
                    {
                        area: "北京",
                        id: 2,
                    },
                    {
                        area: "上海",
                        id: 3,
                    },
                    {
                        area: "甘肃",
                        id: 4,
                    },
                    {
                        area: "重庆",
                        id: 5,
                    },
                    {
                        area: "青海",
                        id: 6,
                    },
                    {
                        area: "安徽",
                        id: 7,
                    },
                    {
                        area: "湖南",
                        id: 8,
                    },
                    {
                        area: "河南",
                        id: 9,
                    },
                    {
                        area: "辽宁",
                        id: 10,
                    },
                    {
                        area: "贵州",
                        id: 11,
                    },
                    // {
                    //   area: "上海",
                    //   id: 12,
                    // },
                    {
                        area: "广东",
                        id: 13,
                    },
                    {
                        area: "浙江",
                        id: 14,
                    },
                    {
                        area: "海南",
                        id: 15,
                    },
                    {
                        area: "江苏",
                        id: 16,
                    },
                    {
                        area: "山东",
                        id: 17,
                    },
                    {
                        area: "山西",
                        id: 18,
                    },
                    {
                        area: "湖北",
                        id: 19,
                    },
                    {
                        area: "广西",
                        id: 20,
                    },
                    {
                        area: "福建",
                        id: 21,
                    },
                    {
                        area: "河北",
                        id: 22,
                    },
                    {
                        area: "内蒙古",
                        id: 23,
                    },
                    {
                        area: "宁夏",
                        id: 24,
                    },
                    {
                        area: "江西",
                        id: 25,
                    },
                    {
                        area: "吉林",
                        id: 26,
                    },
                    {
                        area: "云南",
                        id: 27,
                    },
                    {
                        area: "新疆",
                        id: 28,
                    },
                    {
                        area: "山西",
                        id: 29,
                    },
                    {
                        area: "西藏",
                        id: 30,
                    },
                    {
                        area: "黑龙江",
                        id: 31,
                    },
                ], //出版地区
                publishcycle: [
                    "周二刊",
                    "周刊",
                    "半月刊",
                    "月刊",
                    "双月刊",
                    "季刊",
                    "半年刊",
                    "年刊",
                    "旬刊",
                ], //发行周期
                checktime: ["1个月以内", "1-3个月", "3-6个月", "6-9个月", "1年以上"], //审校时间
                xstype: "", //学术分类
                qkinclude: "", //期刊受录
                fxcycle: "", //发行周期
                cbarea: "", //出版地区
                xstime: "", //审时间
                yxnum: "", // 影响因子
                qkincludenum: 10000, //期刊受录
                fxcyclenum: 10000, //发行周期
                cbareanum: 10000, //出版地区
                xstimenum: 10000, //审时间
                yxtimenum: 10000, //影响因子
                baseon: 0,
                //期刊数据
                magzine: [],
            };
        },
        inject: ['reload'],



        watch: {
            //监听路由变化
            '$route' (to, from) {
                if(to.name == 'classification' || from.name == 'classification') {
                 //   alert(2);
                    //重新调用接口
                    this.current_change(this.$route.query.ids);
                }
            }
        },
        methods: {

            // 分页
            current_change: function (currentPage) {
           //     debugger;
                this.$router.push({
                    path: "/classification",
                    query: {
                        ids: currentPage
                    },

                });
                if(this.$route.query.id!=undefined){

                    this.currentPage = this.$route.query.ids;
                    this.myLiterature();

                }else {
                    this.currentPage = currentPage;
                    this.myLiterature();

                }



            },
            handleSizeChange(val) {
                console.log(val);
                this.pagesize = val;
                this.myLiterature();
            }, //学术分类接口
            myAcademic() {
                Academic().then((res) => {
                    console.log(res);
                    this.type = res.data.data;
                });
            },
            myInclude() {
                //期刊收录接口
                Include().then((res) => {
                    console.log(res);
                    this.include = res.data.data;
                });
            },
            typebx() {
                //点击不限的5个方法
                event.stopPropagation();
                this.xstype = "";
                this.myLiterature();
            },
            inclucebx() {
                event.stopPropagation();
                this.qkinclude = "";
                this.qkincludenum = 10000;
                this.myLiterature();
            },
            cyclebx() {
                event.stopPropagation();
                this.fxcycle = "";
                this.fxcyclenum = 10000;
                this.myLiterature();
            },
            areabx() {
                event.stopPropagation();
                this.cbarea = "";
                this.cbareanum = 10000;
                this.myLiterature();
            },
            timebx() {
                event.stopPropagation();
                this.xstime = "";
                this.xstimenum = 10000;
                this.myLiterature();
            },
            timebxs() {
                event.stopPropagation();
                this.yxtime = "";
                this.yxnum = "";
                this.yxtimenum = 10000;
                this.myLiterature();
            },
            selecttype(e) {
              //   debugger;
              // //  选择类型后的5个方法
              //   this.$router.push({
              //       path: "/classification",
              //       query: {
              //           xstype: e
              //       },
              //
              //   });
              //   if(this.$route.query.xstype!=undefined){
              //
              //       event.stopPropagation();
              //       this.xstype = this.$route.query.xstype;
              //       this.myLiterature();
              //
              //   }else {
                    event.stopPropagation();
                    this.xstype = e;
                    this.myLiterature();

                 // }


            },
            selectinclude(id, num) {
                //
                // this.$router.push({
                //     path: "/classification",
                //     query: {
                //         qkinclude: id
                //
                //     },
                //
                // });
                // if(this.$route.query.qkinclude!=undefined){
                //
                //     event.stopPropagation();
                //     this.qkinclude = this.$route.query.qkinclude;
                //     this.qkincludenum = id;
                //     this.myLiterature();
                //
                // }else {
                //     event.stopPropagation();
                //     this.qkinclude = id;
                //     this.qkincludenum = id;
                //     this.myLiterature();
                //
                // }




                event.stopPropagation();
                this.qkinclude = id;
                this.qkincludenum = id;
                this.myLiterature();
            },
            selectcycle(id, num) {
                // this.$router.push({
                //     path: "/classification",
                //     query: {
                //         fxcycle: id,
                //         fxcyclenum:num
                //     },
                //
                // });
                // if(this.$route.query.fxcycle!=undefined && this.$route.query.fxcyclenum!=undefined){
                //
                //     event.stopPropagation();
                //     this.fxcycle = id;
                //     this.fxcyclenum = num;
                //     this.myLiterature();
                //
                // }else {
                //     event.stopPropagation();
                //     this.fxcycle = this.$route.query.fxcycle;
                //     this.fxcyclenum = this.$route.query.fxcyclenum;
                //     this.myLiterature();
                //
                // }


                event.stopPropagation();
                this.fxcycle = id;
                this.fxcyclenum = num;
                this.myLiterature();
            },
            selectarea(id, num) {
                event.stopPropagation();
                this.cbarea = id;
                this.cbareanum = num;
                this.myLiterature();
            },
            selecttime(id, num) {
                event.stopPropagation();
                this.xstime = id;
                this.xstimenum = num;
                this.myLiterature();
            },
            selecttimes(id, num) {
                event.stopPropagation();
                this.yxnum = id;
                this.yxtimenum = num;
                this.myLiterature();
            },
            baseontime() {
                //依据的三个方法
                this.baseon = 0;
                this.myLiterature();
            },
            baseoncomment() {
                this.baseon = 1;
                this.myLiterature();
            },
            baseonpop() {
                this.baseon = 2;
                this.myLiterature();
            },
            myLiterature() {
                //学术分类接口
                // sessionStorage.clear();
                // sessionStorage.setItem("xstype", this.xstype);
                // sessionStorage.setItem("qkinclude", this.qkinclude);
                // sessionStorage.setItem("fxcycle", this.fxcycle);
                // sessionStorage.setItem("xstime", this.xstime);
                // sessionStorage.setItem("cbarea", this.cbarea);
                // sessionStorage.setItem("yxnum", this.yxnum);
                // sessionStorage.setItem("baseon", this.baseon);

                if (this.$route.query.now == "教育期刊") {
                    this.xstype = 1
                    this.$route.query.now = "";
                } else if (this.$route.query.now == "经济期刊") {
                    sessionStorage.setItem("xstype", 3);
                    this.xstype = 3
                    this.$route.query.now = "";
                }else if(this.$route.query.now == "推荐期刊"){
                    this.xstype = ""
                    this.$route.query.now = "";
                }


                Classification(
                    this.currentPage,
                    this.pagesize,
                    this.xstype,
                    this.qkinclude,
                    this.fxcycle,
                    this.cbarea,
                    this.xstime,
                    this.yxnum,
                    this.baseon
                ).then((res) => {
                //    alert(this.currentPage);
                    console.log('xstype', this.xstype);
                    console.log(res);
                    var that = this;
                    if (this.$route.query.id) {

                        setTimeout(function () {
                            this.magzine = res.data.data;
                            this.total = res.data.total;
                            console.log("okokokokoko");
                        }, 30);

                    } else {
                        this.magzine = res.data.data;
                        this.total = res.data.total;
                    }
                });
            },
            showmagzinedetail(e, s) {
                this.$router.push({
                    path: "/postingdetails",
                    query: {
                        mid: e,
                        qkId: s,
                        mypage:this.$route.query.ids
                    },
                });
            },


            mysearch(nr) {
                console.log(this.magzine, "?????????????????????");
                searchdoc(nr, this.currentPage, this.pagesize, this.$route.query.id).then(
                    (res) => {
                        // console.log(res.data.data,'搜索的数据')
                        var that = this;

                        // for (var i = 0; i <res.data.data.length; i++) {
                        // 	res.data.data[i].cover = that.URL + res.data.data[i].cover
                        // }
                        this.magzine = res.data.data;
                        console.log(this.magzine, "?????????????????????");
                        this.total = res.data.total;
                    }
                );
            },
        },
        mounted() {


            console.log("获取筛选缓存");
            if (sessionStorage.getItem("xstype") != 'null') {
                this.xstype = sessionStorage.getItem("xstype");
            } else {
                this.xstype = "";
            }
            if (sessionStorage.getItem("qkinclude") != 'null') {
                this.qkinclude = sessionStorage.getItem("qkinclude");
            } else {
                this.qkinclude = "";
            }
            if (sessionStorage.getItem("fxcycle") != 'null') {
                this.fxcycle = sessionStorage.getItem("fxcycle");
            } else {
                this.fxcycle = "";
            }
            if (sessionStorage.getItem("xstime") != 'null') {
                this.xstime = sessionStorage.getItem("xstime");
            } else {
                this.xstime = "";
            }
            if (sessionStorage.getItem("cbarea") != 'null') {
                this.cbarea = sessionStorage.getItem("cbarea");
            } else {
                this.cbarea = "";
            }
            if (sessionStorage.getItem("yxnum")) {
                this.yxnum = sessionStorage.getItem("yxnum");
            } else {
                this.yxnum = "";
            }
            if (sessionStorage.getItem("baseon")) {
                this.baseon = sessionStorage.getItem("baseon");
            } else {
                this.baseon = "";
            }
            console.log('xstype', this.xstype)
            this.myLiterature();
            this.myAcademic();
            this.myInclude();
            if (this.$route.query.name) {
                this.mysearch(this.$route.query.name);
            }
        },
    };
</script>

<style>
    .app {
        background-color: #fff;
        overflow: hidden;
    }

    .box {
        width: 80%;
        margin: 0 auto;
    }

    .box1 {
        margin: 30px auto 20px;
    }

    .sorttitile {
        background-color: #f2f2f2;
        display: flex;
        align-items: center;
        padding: 0 8px;
        font-size: 14px;
        color: #666;
    }

    .dropdown {
        height: 50px;
        background: #f2f2f2;
        margin-bottom: 20px;
        overflow: hidden;
        border-radius: 4px;
    }

    .qkfl {
        padding: 20px;
        box-sizing: border-box;
        width: 950px;
    }

    .qkfl span {
        display: inline-block;
        padding: 0 5px;
        text-align: center;
        cursor: pointer;
        margin: 0 3px;
    }

    .dropdown .dropdown-link {
        display: inline-block;
        margin: 15px 10px 15px 20px;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
    }

    .dropdown .dropdown-link i {
        margin-left: 6px;
    }

    .dropdown .dropdown-color {
        color: #141414;
    }

    .magzine {
        padding-bottom: 30px;
        display: flex;
        justify-content: space-between;
    }

    .magzine .mthem {
        text-align: center;
        height: 42px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #141414;
        line-height: 42px;
        margin-bottom: 20px;
    }

    .mcont {
        margin-top: 30px;
    }

    .magpic {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    .magpic div {
        text-align: center;
        width: 18%;
        cursor: pointer;
        margin: 0 5px 10px 5px;
    }

    .magpic div span {
        display: block;
        margin: 8px 0;
    }

    .magpic div img {
        width: 180px;
        height: 220px;
    }

    .orderPage {
        height: 73px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .active {
        color: #fff;
        padding: 0 5px;
        background: #ff4318;
        border-radius: 4px;
        height: 20px;
        line-height: 20px;
    }

    .choose {
        margin-top: 20px;
        height: 50px;
        background: #f2f2f2;
        border-radius: 4px;
        line-height: 50px;
    }

    .choose div {
        position: relative;
        float: left;
        font-size: 14px;
        margin: 0 20px;
        cursor: pointer;
        color: #999;
    }

    .choose div::after {
        width: 0;
        height: 0;
        display: block;
        position: absolute;
        top: 20px;
        right: -20px;
        content: "";
        border: 6px solid #999;
        border-left: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid transparent;
    }

    .choose .baseon {
        position: relative;
        float: left;
        font-size: 14px;
        margin: 0 20px;
        cursor: pointer;
        color: #000000;
    }

    .choose .baseon::after {
        width: 0;
        height: 0;
        display: block;
        position: absolute;
        top: 20px;
        right: -20px;
        content: "";
        border: 6px solid #000;
        border-left: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid transparent;
    }

    .page {
        height: 100px;
        width: 420px;
        margin: 0 auto;
        line-height: 100px;
        margin-top: 30px;
    }
</style>
