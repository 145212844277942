<template>
  <div>
    <!--头部-->
    <div class="headerred">
      <div class="zw"></div>
      <div class="hcont">
        <div class="logoindex">
          <router-link to="/"
            ><img  src="../../assets/slices/logo.png" alt=""
          /></router-link>
        </div>
        <div class="zw"></div>
        <div class="mines">
          <img :src="avater" />
          <el-dropdown class="fonoCenter">
            <span class="el-dropdown-link" style="color: #fff; margin: 0 10px">
              {{ identify }}<i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                ><div @click="exits()">退出</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!--子路由-->
    <router-view></router-view>
    <!--尾部-->
    <div class="footer">
      <div class="w">
        <div class="f_cont">
          <ul class="cont_title">
            <li class="first">
              <img src="../../../Newimg/bottom1.png" alt="" />
              <div class="bottom">
                <div class="tel">010-53559010</div>
                <div class="teltext">全国统一客服热线</div>
                <div class="icon">
                  <img src="../../../Newimg/qq.png" alt="" />
                  <img src="../../../Newimg/weixin.png" alt="" />
                  <img src="../../../Newimg/weibo.png" alt="" />
                </div>
              </div>
            </li>
            <li v-for="(item, index) in ftitle" :key="index">
              <div class="ftitle" @click="update(index)">
                <router-link :to="item.path">{{ item.title }}</router-link>
              </div>
              <div class="bottomtext">
                <p
                  v-for="(val, i) in item.fbody"
                  :key="i"
                  @click="childupdate(index, i)"
                >
                  <router-link :to="val.path"
                    ><span style="color: #757575">{{
                      val.title
                    }}</span></router-link
                  >
                </p>
              </div>
            </li>
            <li>
              <div class="ftitle" @click="update(index)">
                <a>{{ kefu.title }}</a>
              </div>
              <div class="bottomtext">
                <p v-for="(val, i) in kefu.fbody" :key="i" @click="startChat()">
                  <a>
                    <span style="color: #757575">{{ val.title }}</span>
                  </a>
                </p>
              </div>
            </li>

            <li class="last">
              <div class="ftitle">扫码关注</div>
              <img src="../../assets/slices/ewm.png" alt="" />
            </li>
          </ul>
        </div>
      </div>
      <div class="bottomBox">
        <div class="link">
          <div>友情链接：</div>
          <li v-for="val in linklist">
            <a :href="val.name_url"
              ><span class="texts">{{ val.name }}</span></a
            >
          </li>
        </div>
        <div class="copys">
          <li class="links" v-for="val in linklists">
            <a :href="url + val.content"
              ><span class="text">{{ val.name }}</span></a
            >
          </li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { exits } from "../../API/exits.js";

import { links, selectSoftPaper } from "@/API/homepage/policy.js";
import {
  showsidentify,
  showsQKidentify,
  showsZZidentify,
} from "../../API/identify.js";

export default {
  data() {
    return {
      fid: "",
      now: 0,
      page: 0,
      limit: 10,
      kefu: {
        title: "联系我们",
        path: "/homePageBottom/?fid=5&cid=0",
        fbody: [
          { title: "投稿客服", path: "/tflc" },
          { title: "顾问客服", path: "/zntg" },
          { title: "入驻客服", path: "/ptbz" },
        ],
      },
      //身份信息
      identify: "默认",
      //头像
      avater: require("../../assets/image/ygtx.jpg"),
      //头部导航数据
      url: "",
      navlist: [
        {
          title: "智选期刊",
          name: "magzine",
        },
        {
          title: "智选文献",
          name: "document",
        },
        {
          title: "期刊入驻",
          name: "enter",
        },
        {
          title: "关于我们",
          name: "about",
        },
         {
          title: "新闻动态",
          path: "/newsList",
        },
      ],
      //尾部数据
      ftitle: [
        {
          title: "关于我们",
          path: "/about",
          fbody: [
            {
              title: "公司简介",
              path: "/about?now=3&id=aboutUs",
            },
            {
              title: "企业文化",
              path: "/about?now=3&id=culture",
            },
            {
              title: "版权声明",
              path: "/about?now=3&id=copyright",
            },
            {
              title: "工作环境",
              path: "/about?now=3&id=environment",
            },
            // { "title": "团建活动", "path": "/homePageBottom/about/event?fid=0&cid=4" },
            // { "title": "荣誉资质", "path": "/homePageBottom/about/honour?fid=0&cid=5" },
            {
              title: "加入我们",
              path: "/homePageBottom/about/joinus?fid=0&cid=6",
            },
          ],
        },
        {
          title: "智能投稿",
          path: "/intelligentand?now=-1",
          fbody: [
            {
              title: "投发流程",
              path: "/intelligentand?now=-1&id=process",
            },
            {
              title: "平台优势",
              path: "/intelligentand?now=-1&id=youshi",
            },
            {
              title: "平台保证",
              path: "/intelligentand?now=-1&id=guarantee",
            },
            {
              title: "常见问题",
              path: "/intelligentand?now=-1&id=questions",
            },
          ],
        },
        {
          title: "平台入驻",
          path: "/enter?now=2",
          fbody: [
            {
              title: "期刊入驻",
              path: "/enter?now=2",
            },
            {
              title: "服务商入驻",
              path: "/homePageBottom/enter/service?fid=2&cid=1",
            },
            { title: "常见问题", path: "/homePageBottom/enter/question?now=2" },
          ],
        },
        {
          title: "顾问团队",
          path: "/Consultantteam?now=-1",
          fbody: [
            { title: "顾问服务", path: "/Consultantteam?now=-1&id=server" },
            { title: "常见问题", path: "/Consultantteam?now=-1&id=question" },
          ],
        },
      ],
      //友情链接数据
      linklist: [],
      //软著
      linklists: [],
    };
  },
  methods: {
    startChat(){
      window._MICHAT.startChat();
    },
    // 请求底部菜单
    linksList() {
      links(this.page, this.limit).then((res) => {
        console.log(res);
        this.linklist = res.data.data;
      });
    },
    // 软著
    selectSoftPaperlist() {
      selectSoftPaper().then((res) => {
        console.log(res);
        this.linklists = res.data.data;
        console.log(this.url + "/" + res.data.data[0].content);
      });
    },
    exits() {
      exits().then((res) => {
        console.log(res);
        this.$router.push("/login");
        sessionStorage.clear();
      });
    },
    update(e) {
      this.fid = e;
      this.now = 0;
    },
    childlink(e, path) {
      this.now = e;
      console.log(e);
      this.$router.push({ path: path, query: { fid: 0, cid: e } });
    },
    childupdate(fid, cid) {
      this.fid = fid;
      this.now = cid;
    },
    fonoCenter() {
      console.log(11111111);
      this.$router.push("/campoCenter");
    },
    showpeople() {
      var code = localStorage.getItem("code");
      var id = localStorage.getItem("id");
      if (code == 1) {
        this.identify = "服务商";
        showsidentify(id, code).then((res) => {
          if (res.data.data.headimg.length != 0) {
            this.avater = this.URL + res.data.data.headimg;
            sessionStorage.setItem("avater", this.avater);
          }
        });
      } else if (code == 2) {
        this.identify = "员工";
        showsidentify(id, code).then((res) => {
          if (res.data.data.headimg.length != 0) {
            this.avater = this.URL + res.data.data.headimg;
            sessionStorage.setItem("avater", this.avater);
          }
        });
      } else if (code == 3) {
        this.identify = "期刊";
        showsQKidentify(id).then((res) => {
          if (res.data.data.headimg.length != 0) {
            this.avater = this.URL + res.data.data.headimg;
            sessionStorage.setItem("avater", this.avater);
          }
        });
      } else {
        this.identify = "作者";
        showsZZidentify(id).then((res) => {
          if (res.data.data.headimg.length != 0) {
            this.avater = this.URL + res.data.data.headimg;
            sessionStorage.setItem("avater", this.avater);
          }
        });
      }
    },
  },
  mounted() {
    this.url = this.URL;
    this.showpeople();
    this.linksList();
    this.selectSoftPaperlist();
    let _this = this; //很重要把父路由的vue实例赋给_this
    _this.$root.$on("next1", function () {
      //监听next事件，这里$root很重要，不懂去官网介绍
      _this.showpeople(); //调用父路由中的方法
    });
    this.fid = this.$route.query.fid;
    if (this.$route.query.cid) {
      this.now = this.$route.query.cid;
    }
  },
};
</script>

<style>
/*头部样式*/
.headerred {
  width: 100%;
  height: 100px;
  background: #ff1918;
}
.hcont {
  margin-left: 10%;
  width: 88%;
  display: flex;
  justify-content: space-between;
  height: 100px;
  align-items: center;
}
.logoindex {
  width: 160px;
  height: 58px;
}

.logoindex img {
  width: 160px;
  height: 58px;
  object-fit: contain;
}

.zw {
  width: 30px;
}

.mines {
  cursor: pointer;
  font-size: 14px;
  margin-right: 150px;
}

.mines img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  vertical-align: middle;
}

.f_cont {
  padding-top: 35px;
}

.cont_title {
  display: flex;
}

.cont_title li {
  flex: 1;
}

.mines img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  vertical-align: middle;
}

.f_cont {
  padding-top: 35px;
}

.cont_title {
  display: flex;
}

.cont_title li {
  flex: 1;
}

.cont_title .first {
  flex: 2;
}

.first img {
  width: 85%;
  height: 78px;
}

.bottom {
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.tel {
  width: 168px;
  height: 33px;
  font-size: 24px;
  line-height: 33px;
}

.teltext {
  width: 112px;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
}

.icon {
  display: flex;
}

.icon img {
  width: 34px;
  height: 34px;
  margin: 3px;
}

.ftitle {
  height: 78px;
  line-height: 78px;
}

.bottomtext {
  font-size: 13px;
}

.bottomtext p {
  margin: 10px 0;
}

.last img {
  width: 78px;
  height: 78px;
}
.bottomBox {
  padding-bottom: 20px;
  background-image: url(../../../Newimg/bottom22.jpg);
}
.link {
  width: 80%;
  margin: 0 auto;
  height: auto;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 30px;
  font-size: 12px;
  border-bottom: 1px solid white;
  color: white;
}
.texts,
.text {
  color: white;
}
.copy {
  line-height: 97px;
  text-align: center;
  font-size: 14px;
  font-size: 12px;
}
.copys {
  color: white;
  font-size: 12px;
  margin: 0 auto;
  color: white !important;
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
}
.links {
  width: 50%;
  height: auto;
  display: block;
}
</style>