<template>
	<div style="padding: 20px; background-color: #fff;">
		<div class="aboutitem" v-for="(item,index) in textlist" in :key="index">
			
			<p class="dl"  v-for="(item,index) in textlist" in :key="index" v-html="item.content">
			
			</p>
		</div>
		
	</div>
</template>

<script>
  import { selectCityYunwei } from "../../../API/footer.js"
  export default {
	
		data() {
			return {
		
        textlist:[],
			}
		},
		mounted() {
      this.copyrightNoticelist()
		},
		methods: {
			copyrightNoticelist() {
			  selectCityYunwei().then(res =>{
       console.log(res);
          this.textlist = res.data.data
          console.log( this.textlist);
        })

			},
    }
  }
</script>


<style>
	.aboutitem{
		margin-bottom:15px;
	}
	.itemtitle{
		
		font-size: 15px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 700;
		color: #141414;
		line-height: 26px;
	}
	.dl{
		margin:5px 0;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		line-height: 26px;
	}
</style>
