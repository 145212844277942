<template>
  <div style="background-color: #fff">
    <div class="toppicd">
      <img src="../../../Newimg/aboutus.jpg" alt="" />
      <div class="topswiper-list">
        <vueSeamless :data="swiperList" :class-option="optionCustomer">
          <div class="topswiper">
            <div
              class="topswiper-item"
              v-for="(item, index) in swiperList"
              :key="index"
            >
              <div class="title">{{ item.title }}</div>
              <div class="c-content" v-html="item.content"></div>
            </div>
          </div>
        </vueSeamless>
      </div>
    </div>
    <div id="aboutUs">
      <mytitle>关于我们</mytitle>
      <div class="w">
        <div class="content">
          <div
            v-html="item.content"
            v-for="(item, index) in aboutUs"
            :key="index"
          ></div>
        </div>
      </div>
    </div>
    <!-- <div id="culture">
      <mytitle>企业文化</mytitle>
      <div class="w">
        <div
          id="cultureBody"
          v-for="(item, index) in textlist"
          :key="index"
          v-html="item.content"
        ></div>
      </div>
    </div> -->
    <div id="environment">
      <mytitle>办公环境</mytitle>
      <div class="w">
        <ul class="fc">
          <li><img src="../../assets/image/aboutSlices/t1.jpg" alt="" /></li>
          <li>
            <span>2021.3.31</span>
            <p>公司环境展示</p>
          </li>
          <li><img src="../../assets/image/aboutSlices/t2.jpg" alt="" /></li>
          <li>
            <span>2021.3.31</span>
            <p>公司环境展示</p>
          </li>
          <li><img src="../../assets/image/aboutSlices/t3.jpg" alt="" /></li>
          <li>
            <span>2021.3.31</span>
            <p>公司环境展示</p>
          </li>
          <li><img src="../../assets/image/aboutSlices/t4.jpg" alt="" /></li>
          <li>
            <span>2021.3.31</span>
            <p>公司环境展示</p>
          </li>
          <li><img src="../../assets/image/aboutSlices/t5.jpg" alt="" /></li>
          <li>
            <span>2021.3.31</span>
            <p>公司环境展示</p>
          </li>
          <li><img src="../../assets/image/aboutSlices/t6.jpg" alt="" /></li>
          <li>
            <span>2021.3.31</span>
            <p>公司环境展示</p>
          </li>
        </ul>
      </div>
    </div>
    <div id="copyright" style="padding-bottom: 1px">
      <mytitle>版权声明</mytitle>
      <div class="w">
        <div class="tab">
          <ul>
            <div v-for="(item, index) in copyright" :key="index">
              <li class="table" v-if="item.isDelete == 0">
                <div class="title">
                  <!-- <img src="../../assets/image/aboutSlices/1.png" alt="" /> -->

                  {{ item.name }}
                </div>
                <div class="cont" v-html="item.content"></div>
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mytitle from "@/components/title.vue";
import vueSeamless from "vue-seamless-scroll";
import {
  selectCompanyCulture,
  companyIntroduce,
  copyrightNotice,
} from "@/API/footer.js";
export default {
  name: "Article",
  components: {
    mytitle,
    vueSeamless,
  },
  data() {
    return {
      textlist: "",
      aboutUs: [],
      copyright: [],
      optionCustomer: {
        step: 1,
        limitMoveNum: 5,
        openTouch: false,
        waitTime: 1,
        direction: 2,
        singleWidth: 30,
      },
      swiperList: [
        {
          title: "企业精神：",
          content: "工匠精神 精于专注",
        },
        {
          title: "企业使命：",
          content: "以科技创新赋<br>学术生态建设",
        },
        {
          title: "企业愿景：",
          content: "打造最智能的学术<br>论文投发管理平台",
        },
        {
          title: "经营理念：",
          content: "客户至上<br>服务第一",
        },
        {
          title: "管理理念：",
          content: "以人为本 追求卓越",
        },
        {
          title: "服务理念：",
          content: "以服务感动客户<br>用专业赢得口碑",
        },
        {
          title: "企业核心价值观：",
          content: "爱国 求实 以义取利<br>主动 向上 利己达人",
        },
      ],
    };
  },
  created() {
    if (this.$route.query.id) {
      this.now = this.$route.query.id;
      setTimeout(() => {
        var element = document.getElementById(this.$route.query.id);
        console.log(element, this.$route.query.id);
        if (element) {
          element.scrollIntoView();
        }
      }, 200);
    }
    this.getdata();
  },
  methods: {
    getdata() {
      companyIntroduce().then((res) => {
        if (res.data.code == 0) {
          this.aboutUs = res.data.data;
        }
      });
      copyrightNotice().then((res) => {
        if (res.data.code == 0) {
          this.copyright = res.data.data;
        }
      });
    },
  },
  watch: {
    $route(to, from) {
      console.log("监听到路由变动了", to.path);
      if (this.$route.query.id) {
        this.now = this.$route.query.id;
        setTimeout(() => {
          var element = document.getElementById(this.$route.query.id);
          console.log(element, this.$route.query.id);
          if (element) {
            element.scrollIntoView();
          }
        }, 200);
      }
    },
  },
  filters: {
    showHtml(str) {
      return str
        .replace(str ? /&(?!#?\w+;)/g : /&/g, "&amp;")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        .replace(/&amp;nbsp;/g, "\u3000");
    },
  },
};
</script>

<style scoped>
.w {
  margin-bottom: 80px;
  margin-top: 60px;
  flex: 1;
}


.tab {
  background: #ffffff;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 25px;
  margin-bottom: 40px;
}

.table {
  border: 1px solid #e6e6e6;
  display: flex;
}

.table .title {
  flex: 2;
  display: flex;
  width: 0;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e6e6e6;
}

.table .cont {
  flex: 8;
  padding: 20px;
  width: 0;
  line-height: 24px;
  font-size: 15px;
  color: #333;
}
.fc {
  display: flex;
  flex-wrap: wrap;
}
.fc li {
  width: 33%;
  text-align: center;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.fc li p {
  line-height: 30px;
}
.fc li span {
  font-weight: 800;
}
.fc li img {
  width: 100%;
}
.fc li:hover {
  background: #293443;
}
.fc li:hover span {
  color: #debc90;
}
.fc li:hover p {
  color: #fff;
}
#aboutUs {
  padding-top: 40px;
}
.toppicd {
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
  overflow: hiddens;
  position: relative;
}
.toppicd img {
  width: 100%;
  height: 320px;
  object-fit: cover;
}
.topswiper-item {
  width: 300px;
  height: 230px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 16px;
  margin: 0 20px;
  padding-top: 60px;
  padding-left: 50px;
  box-sizing: border-box;
}
.topswiper-item .title {
  font-size: 22px;
  color: #fff;
  font-weight: bold;
  text-align: left;
}
.topswiper-item .c-content {
  font-size: 14px;
  color: #fff;
  margin-top: 26px;
  line-height: 26px;
  text-align: left;
}
.topswiper {
  display: flex;
  flex-flow: nowrap;
}
.topswiper-list {
  width: 100%;
  height: 230px;
  position: absolute;
  z-index: 2;
  top: 45px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
}
</style>