<template>
  <div id="body">
    <!--头部-->
    <div class="header">
      <div class="zw"></div>
      <div class="hcont">
        <div class="logoindex">
          <img
            @click="linktohomepage"
            src="../assets/slices/logo.png"
            alt=""
            style="cursor: pointer"
          />
        </div>
        <ul class="nav">
          <li
            v-for="(item, index) in navlist"
            :key="index"
            :class="activenav == index ? 'activenav' : ''"
            @click="changeactive(index)"
          >
            <router-link
              v-if="item.title != '平台入驻'"
              :to="{ path: item.path, query: { now: index } }"
              >{{ item.title }}</router-link
            >
            <el-dropdown v-else trigger="click">
              <a class="pingtai">{{ item.title }}<i class="el-icon-caret-bottom el-icon--right"></i></a>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item class="clearfix">
                  <div @click="qikanruzhu">期刊入驻</div>
                </el-dropdown-item>
                <el-dropdown-item class="clearfix">
                  <div @click="fuwushang">服务商入驻</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
        <div class="seach">
          <div class="seach-box">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                {{ sqk }}<i class="el-icon-caret-bottom el-icon--right"></i>
              </span>

              <el-dropdown-menu slot="dropdown" @change="aa()">
                <!-- 0:刊名，1:题目，2:作者，3:作者单位，4:关键词 -->
                <div @click="setshowqk(0)">
                  <el-dropdown-item class="clearfix">刊名</el-dropdown-item>
                </div>
                <div @click="setshowqk(1)">
                  <el-dropdown-item class="clearfix">题目</el-dropdown-item>
                </div>
                <div @click="setshowqk(2)">
                  <el-dropdown-item class="clearfix">作者</el-dropdown-item>
                </div>
                <div @click="setshowqk(3)">
                  <el-dropdown-item class="clearfix">作者单位</el-dropdown-item>
                </div>
                <div @click="setshowqk(4)">
                  <el-dropdown-item class="clearfix">关键词</el-dropdown-item>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

          <input type="text" v-model="docname" />
          <!-- 搜期刊 -->

          <button @click="searchwx">搜索</button>
        </div>
        <div class="zw"></div>
        <div class="mines">
          <img v-if="usercode == '010'" src="../assets/image/setting/pic.jpg" />
          <img v-else :src="avater" alt="" />
          <el-dropdown trigger="click">
            <span class="el-dropdown-link userName">
              <span v-if="usercode == 1">服务商</span>
              <span v-if="usercode == 2">员工</span>
              <span v-if="usercode == 3">期刊</span>
              <span v-if="usercode == 0">作者</span>
              <span v-if="usercode == '010'">未登录</span>
              <i class="el-icon-caret-bottom el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="clearfix" v-if="userid.length == 0">
                <div @click="loginorregister">登录/注册</div>
              </el-dropdown-item>
              <el-dropdown-item class="clearfix" v-if="userid.length != 0">
                <div @click="houtai">个人中心</div>
              </el-dropdown-item>
              <el-dropdown-item class="clearfix" v-if="userid.length != 0">
                <div @click="myexits">退出登录</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!--子路由-->
    <router-view ref="childmain"></router-view>
    <!--尾部-->
    <div class="footer">
      <div class="w">
        <div class="f_cont">
          <ul class="cont_title">
            <li class="first">
              <img src="../../Newimg/bottom1.png" alt="" />
              <div class="bottom">
                <div class="tel">010-53559010</div>
                <div class="teltext">全国统一客服热线</div>
                <div class="icon">
                  <img src="../../Newimg/qq.png" alt="" />
                  <img src="../../Newimg/weixin.png" alt="" />
                  <img src="../../Newimg/weibo.png" alt="" />
                </div>
              </div>
            </li>
            <li v-for="(item, index) in ftitle" :key="index">
              <div class="ftitle" @click="update(index)">
                <router-link :to="item.path">{{ item.title }}</router-link>
              </div>
              <div class="bottomtext">
                <p
                  v-for="(val, i) in item.fbody"
                  @click="childupdate(index, i)"
                  :key="i"
                >
                  <router-link :to="val.path"
                    ><span style="color: #757575">{{
                      val.title
                    }}</span></router-link
                  >
                </p>
              </div>
            </li>
            <li>
              <div class="ftitle" @click="update(index)">
                <a>{{ kefu.title }}</a>
              </div>
              <div class="bottomtext">
                <p v-for="(val, i) in kefu.fbody" :key="i" @click="startChat()">
                  <a>
                    <span style="color: #757575">{{ val.title }}</span>
                  </a>
                </p>
              </div>
            </li>
            <li class="last">
              <div class="ftitle">扫码关注</div>
              <img src="../assets/slices/ewm.png" alt="" />
            </li>
          </ul>
        </div>
      </div>
      <div class="bottomBlue">
        <div class="link">
          <div>友情链接：</div>
          <li v-for="(val, i) in linklist" :key="i">
            <a :href="val.name_url"
              ><span class="texts">{{ val.name }}</span></a
            >
          </li>
        </div>
        <div class="copys">
          <li class="links" v-for="(val, i) in linklists" :key="i">
            <a :href="url + val.content"
              ><span class="text">{{ val.name }}</span></a
            >
          </li>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { links, searchdoc, selectSoftPaper } from "@/API/homepage/policy.js";
import { exits } from "../API/exits.js";
import { searchInfo } from "@/API/homepage/document/document.js";

export default {
  data() {
    return {
      userid: "", //登陆用户id
      usercode: "010", //登陆用户的code
      avater: require("../assets/image/ygtx.jpg"), //用像户头
      fid: "",
      now: 0,
      sqk: "刊名",
      showqk: 0,
      docname: "",
      activenav: 0,
      page: 1,
      limit: 10,
      //头部导航数据
      navlist: [
        {
          title: "智选期刊",
          name: "magzine",
          path: "/magzine",
        },
        {
          title: "智选文献",
          name: "document",
          path: "/document",
        },
        {
          title: "平台入驻",
          name: "enter",
          path: "/enter",
        },
        {
          title: "关于我们",
          name: "about",
          path: "/about",
        },
        {
          title: "新闻动态",
          path: "/newsList",
        },
      ],

      //尾部数据
      ftitle: [
        {
          title: "关于我们",
          path: "/about",
          fbody: [
            {
              title: "公司简介",
              path: "/about?now=3&id=aboutUs",
            },
            {
              title: "企业文化",
              path: "/about?now=3&id=culture",
            },
            {
              title: "版权声明",
              path: "/about?now=3&id=copyright",
            },
            {
              title: "工作环境",
              path: "/about?now=3&id=environment",
            },
            // { "title": "团建活动", "path": "/homePageBottom/about/event?fid=0&cid=4" },
            // { "title": "荣誉资质", "path": "/homePageBottom/about/honour?fid=0&cid=5" },
            {
              title: "加入我们",
              path: "/homePageBottom/about/joinus?fid=0&cid=6",
            },
          ],
        },
        {
          title: "智能投稿",
          path: "/intelligentand?now=-1",
          fbody: [
            {
              title: "投发流程",
              path: "/intelligentand?now=-1&id=process",
            },
            {
              title: "平台优势",
              path: "/intelligentand?now=-1&id=youshi",
            },
            {
              title: "平台保证",
              path: "/intelligentand?now=-1&id=guarantee",
            },
            {
              title: "常见问题",
              path: "/intelligentand?now=-1&id=questions",
            },
          ],
        },
        {
          title: "平台入驻",
          path: "/enter?now=2",
          fbody: [
            {
              title: "期刊入驻",
              path: "/enter?now=2",
            },
            {
              title: "服务商入驻",
              path: "/homePageBottom/enter/service?fid=2&cid=1",
            },
            { title: "常见问题", path: "/homePageBottom/enter/question?now=2" },
          ],
        },
        {
          title: "顾问团队",
          path: "/Consultantteam?now=-1",
          fbody: [
            { title: "顾问服务", path: "/Consultantteam?now=-1&id=server" },
            { title: "常见问题", path: "/Consultantteam?now=-1&id=question" },
          ],
        },
      ],
      kefu: {
        title: "联系我们",
        path: "/homePageBottom/?fid=5&cid=0",
        fbody: [
          { title: "投稿客服", path: "/tflc" },
          { title: "顾问客服", path: "/zntg" },
          { title: "入驻客服", path: "/ptbz" },
        ],
      },
      //友情链接数据
      linklist: [
        // { "title": "中国科学院文献情报中心", "path": "http://www.sciencechina.cn" },
        // { "title": "超星", "path": "http://www.chaoxing.com" },
        // { "title": "龙源期刊网", "path": "http://www.qikan.com", },
        // { "title": "中国学术期刊综合评价数据库", "path": "http://www.cajced.net/" },
        // { "title": "中国国家图书馆", "path": "http://www.nlc.cn" },
        // { "title": "中国社会科学研究评价中心", "path": "http://cssci.nju.edu.cn" },
        // { "title": "上海图书馆", "path": "http://library.sh.cn" },
        // { "title": "中国知网", "path": "http://www.cnki.net" },
        // { "title": "维普网", "path": "http://www.cqvip.com" }
      ],
      //软著
      linklists: [
        { title: "出版物经营许可证", path: "http://www.sciencechina.cn" },
        {
          title: "广播电视节目制作经营许可证",
          path: "http://www.chaoxing.com",
        },
        { title: "刊大师期刊智能投放平台APP", path: "http://www.qikan.com" },
        { title: "网络文化经营许可证", path: "http://www.cajced.net/" },
        {
          title: "增值电信业务经营许可证(编号：B1.B2-20195219)",
          path: "http://www.nlc.cn",
        },
        {
          title: "增值电信业务经营许可证：京B2-20191000",
          path: "http://cssci.nju.edu.cn",
        },
      ],
      linklists: [],
      url: "",
    };
  },
  mounted() {
    this.url = this.URL;
    this.linksList();
    this.selectSoftPaperlist();
    this.fid = this.$route.query.fid;
    if (this.$route.query.now) {
      this.activenav = this.$route.query.now;
    }

    if (this.$route.query.cid) {
      console.log("顶部变动cid=", this.$route.query.cid);
      this.now = this.$route.query.cid;
    }
    if (sessionStorage.getItem("sid")) {
      this.userid = sessionStorage.getItem("sid");
      this.usercode = sessionStorage.getItem("scode");
      if (sessionStorage.getItem("avater")) {
        this.avater = sessionStorage.getItem("avater");
      }
    }
  },
  watch: {
    $route(to, from) {
      console.log("监听到路由变动了", to.path);
      if (this.$route.query.now) {
        this.activenav = this.$route.query.now;
      }
    },
  },
  methods: {
    startChat(){
      window._MICHAT.startChat();
    },
    qikanruzhu() {
      this.activenav = 2;
      this.$router.push("/enter?now=2");
    },
    fuwushang() {
      this.activenav = 2;
      this.$router.push("/homePageBottom/enter/service");
    },
    searchqk() {
      //搜索跳转文献页面
      console.log(this.showqk);
      if (this.$route.path == "/article") {
        if (this.docname.length == 0) {
          this.$message.warning("请输入搜索内容");
        } else {
          searchdoc(this.docname, this.page, this.limit, this.showqk).then(
            (res) => {
              console.log(res, "aaaaaaaaaaaaares");
              this.$refs.childmain.artlist = res.data.data;
              this.$refs.childmain.total = res.data.total;
            }
          );
        }
      } else {
        if (this.docname.length == 0) {
          this.$router.push({ path: "/article" });
        } else {
          this.$router.push({
            path: "/article",
            query: {
              name: this.docname,
              id: this.showqk,
            },
          });
        }
      }
    },
    // 请求底部菜单
    linksList() {
      links(this.page, this.limit).then((res) => {
        console.log(res);
        this.linklist = res.data.data;
      });
    },
    // 软著
    selectSoftPaperlist() {
      selectSoftPaper().then((res) => {
        console.log(res);
        this.linklists = res.data.data;
      });
    },
    searchwx() {
      if (this.docname.length == 0) {
        this.$message.warning("请输入搜索内容");
      } else {
        searchInfo(1, 100, this.docname, this.showqk).then(
                (res) => {
                  if(res.data.total==0){
                    return   this.$message.error("没有检测到与"+this.docname+"有关的信息");
                  }else {
                    //如果查询的是期刊，导航定位到智选期刊
                    if(this.showqk==0){
                      this.$router.push(
                              "/searchResult?now=0&keyward=" + this.docname + "&type=" + this.showqk
                      );
                    }else {//如果查询的不是期刊，导航定位到智选文献
                      this.$router.push(
                              "/searchResult?now=1&keyward=" + this.docname + "&type=" + this.showqk
                      );
                    }
                  }

                }
        );



      }
    },
    changeactive(e) {
      this.activenav = e;
    },

    update(e) {
      this.fid = e;
      this.now = 0;
    },
    childlink(e, path) {
      this.now = e;
      console.log(e);
      this.$router.push({ path: path, query: { fid: 0, cid: e } });
    },
    childupdate(fid, cid) {
      this.fid = fid;
      this.now = cid;
    },
    loginorregister() {
      this.$router.push("/login");
    },
    houtai() {
      if (this.usercode == 1) {
        this.$router.push("/Services");
      } else if (this.usercode == 2) {
        this.$router.push("/Staff");
      } else if (this.usercode == 3) {
        this.$router.push("/Periodical");
      } else if (this.usercode == 0) {
        this.$router.push("/Pending");
      }
    },
    linktohomepage() {
      this.activenav = -1;
      this.$router.push("/");
    },
    // 0:刊名，1:题目，2:作者，3:作者单位，4:关键词
    setshowqk(e) {
      this.showqk = e;
      switch (e) {
        case 0:
          this.sqk = "刊名";
          break;
        case 1:
          this.sqk = "题目";
          break;
        case 2:
          this.sqk = "作者";
          break;
        case 3:
          this.sqk = "作者单位";
          break;
        case 4:
          this.sqk = "关键词";
          break;
      }
    },
    myexits() {
      exits().then((res) => {
        location.reload();
        sessionStorage.clear();
        localStorage.clear("code");
      });
    },
  },
};
</script>

<style>
/*头部样式*/
.hcont {
  margin-left: 10%;
  width: 80%;
  display: flex;
  height: 100px;
  align-items: center;
}
.header {
  width: 100%;
  height: 100px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.3);
}

.logoinedx {
  width: 160px;
  height: 58px;
}
.logoindex img {
  width: 160px;
  height: 58px;
  object-fit: contain;
}
.nav {
  display: flex;
  flex: 1;
  justify-content: space-around;
  margin-left: 16px;
}
.nav li {
  padding: 5px;
}
.activenav {
  border-bottom: 2px solid #6c9be9;
}
.activenav a {
  color: #6c9be9;
}
.seach {
  position: relative;
  display: flex;
  flex: 1;
  height: 40px;
  /* border: 1px solid #ff1918; */
  box-sizing: border-box;
  /* border-radius: 20px; */
  overflow: hidden;
}
.zw {
  width: 30px;
}
.seach input {
  float: left;
  height: 38px !important;
  /* border: none; */
  outline: none;
  width: 100%;
  padding-left: 100px;
  height: 36px;
  font-size: 14px;
  background-color: #f5f5f5;
  border: 1px solid #6c9be9;
}
.seach button {
  position: absolute;
  right: 0;
  float: left;
  width: 20%;
  margin: 0 0 0 3px;
  height: 40px;
  background: #6c9be9;
  border: none;
  outline: none;
  color: #fff;
  cursor: pointer;
}

.mines {
  cursor: pointer;
  font-size: 14px;
  margin-right: -66px;
}

.mines img {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  vertical-align: middle;
}

/*尾部样式*/
.footer {
  /* padding: 20px 0; */

  width: 100%;
  background: #181818;
  background: url(../../Newimg/bottom11.jpg) no-repeat;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  box-sizing: border-box;
}
.footer a {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #3d3c26;
}
.texts {
  color: #fff;
}
.f_cont {
  padding-top: 35px;
  padding-bottom: 20px;
}
.cont_title {
  display: flex;
}
.cont_title li {
  flex: 1;
}
.cont_title .last {
  flex: 0;
}
.cont_title .first {
  flex: 2;
}
.first img {
  width: 58%;
  height: 90px;
  object-fit: contain;
  object-position: left;
}
.bottom {
  height: 125px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #000000;
}
.tel {
  width: 168px;
  height: 33px;
  font-size: 24px;
  line-height: 33px;
}
.teltext {
  width: 112px;
  height: 20px;
  font-size: 14px;
  line-height: 20px;
}
.icon {
  display: flex;
}
.icon img {
  width: 34px;
  height: 34px;
  margin: 3px;
  /* background-color: #646464; */
}
.ftitle {
  height: 68px;
  line-height: 68px;
}
.ftitle a {
  color: black;
}
.bottomtext {
  font-size: 13px;
  cursor: pointer;
}
.bottomtext p {
  margin: 10px 0;
}
.bottomtext p a span {
  color: #333333 !important;
  cursor: pointer;
}
.last img {
  width: 78px;
  height: 78px;
}
.bottomBlue {
  width: 100%;
  background-image: url(../../Newimg/bottom22.jpg);
}
.link {
  width: 80%;
  margin: 0 auto;
  height: auto;
  display: flex;
  justify-content: space-between;
  display: -webkit-flex;
  flex-direction: row;
  flex-wrap: wrap;
  line-height: 30px;
  font-size: 12px;
  border-bottom: 1px solid #ffffff;
}
.link div,
.link li {
  text-align: center;
}

.copybottom {
  line-height: 100px;
  text-align: center;
  font-size: 14px;
}
.userName {
  margin-left: 5px;
}
.copys {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  padding-bottom: 20px;
  justify-content: center;
}
.links a span {
  font-size: 13px;
  color: #ffffff;
}
.text {
  color: #757575;
}
.links:nth-child(2) {
  text-align: right;
}
.links:nth-child(4) {
  text-align: right;
}
.links:nth-child(6) {
  text-align: right;
}
.links {
  width: 50%;
  height: auto;
  display: block;
}

.last .ftitle {
  color: #333333;
}
.pingtai {
  cursor: pointer;
  color: #333;
  font-size: 16px;
}
.seach-box {
  margin: 5px 3px;
  cursor: pointer;
  line-height: 30px;
  padding-left: 16px;
  width: 90px;
  position: absolute;
  left: 0;
}
</style>