<template>
  <div>
    <div class="bg">
      <div class="w">
        <div class="wcont">
          <div class="artleft">
            <div class="item">
              <!-- <div class="bread">
                <span @click="toLogin">首页</span> -
                <span @click="towenx">智选文献</span> -
                <span>{{ articlelist.name }}</span>
              </div> -->
              <div class="them">{{ articlelist.name }}</div>
              <p class="title">{{ articlelist.authorName }}</p>
              <!-- <p class="title">{{ articlelist.title }}</p> -->
              <p class="title">
                <span>{{ articlelist.authorUnit }}</span>
                <span>{{ articlelist.publishing }}</span>
                <span>{{ articlelist.postcode }}</span>
              </p>
              <!-- <p class="updete_time">更新时间：{{ articlelist.modifytime }}</p> -->
              <div class="xj" style="margin-top: 26px">
                <div class="key">
                  <span>摘要：</span>
                  <div class="cont" v-html="articlelist.content"></div>
                </div>
              </div>
              <div class="xj">
                <div class="key">
                  <span>关键词：</span>{{ articlelist.keyword }}
                </div>
                <div class="download">
                  <div class="d" @click="membersRight()">
                    <img src="../../assets/image/icon/xz.png" alt="" /><span
                      >下载</span
                    >
                  </div>

                  <div
                    class="d"
                    v-if="articlelist.type == 1"
                    @click="qxsupport()"
                  >
                    <img src="../../assets/image/yizan.png" alt="" />
                    <span>{{ articlelist.praise }}</span>
                  </div>
                  <div class="d" @click="support()" v-else>
                    <img src="../../assets/image/icon/zan.png" alt="" />
                    <span>{{ articlelist.praise }}</span>
                  </div>
                </div>
                <el-dialog
                  title="提示"
                  :visible.sync="dialogVisible"
                  width="30%"
                >
                  <span>登录后投稿或收藏</span>
                  <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="gologin"
                      >现在去登录</el-button
                    >
                  </span>
                </el-dialog>
              </div>
            </div>
            <div v-if="status == 0" class="commentnologin">
              <router-link to="/login">登录</router-link>后查看发表评论
            </div>
            <div class="comment" v-else>
              <div class="ctitle">{{ count }}条评论 <span></span></div>
              <div class="commentlist">
                <div class="user" v-for="(item, index) in user" :key="index">
                  <img :src="url + item.headimg" alt="" /><i>{{
                    item.editor
                  }}</i>
                  <span style="float: right">{{ item.createtime }}</span>
                  <div class="sj">
                    <p>{{ item.comment }}</p>
                    <div class="zh">
                      <i
                        @click="myGiveTheThumb(item.id, 0, index)"
                        v-if="item.type == 0"
                        ><img
                          src="../../assets/image/icon/zan.png"
                          alt=""
                          class="ico"
                        />{{ item.praise }}</i
                      >
                      <i
                        @click="myQXThumb(item.id, 0, index)"
                        v-if="item.type == 1"
                        ><img
                          src="../../assets/image/yizan.png"
                          alt=""
                          class="ico"
                        />{{ item.praise }}</i
                      >
                      <i v-if="hf == index" @click="noreplay"
                        ><img
                          src="../../assets/image/icon/back.png"
                          alt=""
                          class="ico"
                        />取消回复</i
                      >
                      <i v-else @click="replay(index)"
                        ><img
                          src="../../assets/image/icon/back.png"
                          alt=""
                          class="ico"
                        />回复</i
                      >
                    </div>
                    <div
                      v-if="hf == index"
                      style="
                        display: flex;
                        align-items: flex-end;
                        padding: 20px;
                        justify-content: space-between;
                      "
                    >
                      <textarea
                        class="reply"
                        :placeholder="'回复' + item.editor"
                        v-model="replycont"
                      ></textarea>
                      <el-button
                        type="danger"
                        @click="
                          myLiteratureReplyComments(item.id, item.author_id)
                        "
                        >发布</el-button
                      >
                    </div>
                  </div>
                  <div
                    class="commentlist2"
                    v-for="(val, i) in item.replylist"
                    :key="i"
                  >
                    <div class="user">
                      <img :src="url + val.headimg" alt="" /><i>{{
                        val.editor
                      }}</i
                      >&nbsp回复&nbsp<i>{{ val.toeditor }}</i>
                      <span style="float: right">{{ val.createtime }}</span>
                      <div class="sj">
                        <p>{{ val.replycontent }}</p>
                        <div class="zh">
                          <i
                            @click="myGiveTheThumb2(val.id, 1, i, index)"
                            v-if="val.type == 0"
                            ><img
                              src="../../assets/image/icon/zan.png"
                              alt=""
                              class="ico"
                            />{{ val.praise }}</i
                          >
                          <i
                            @click="myQXThumb2(val.id, 1, i, index)"
                            v-if="val.type == 1"
                            ><img
                              src="../../assets/image/yizan.png"
                              alt=""
                              class="ico"
                            />{{ val.praise }}</i
                          >
                          <i
                            v-if="hf2 == index && hf22 == i"
                            @click="replay2(index, i)"
                            ><img
                              src="../../assets/image/icon/back.png"
                              alt=""
                              class="ico"
                            />取消回复</i
                          >
                          <i v-else @click="noreplay2(index, i)"
                            ><img
                              src="../../assets/image/icon/back.png"
                              alt=""
                              class="ico"
                            />回复</i
                          >
                        </div>
                        <div
                          v-if="hf2 == index && hf22 == i"
                          style="
                            display: flex;
                            align-items: flex-end;
                            padding: 20px;
                            justify-content: space-between;
                          "
                        >
                          <textarea
                            class="reply"
                            :placeholder="'回复' + val.editor"
                            v-model="replycont"
                          ></textarea>
                          <el-button
                            type="danger"
                            @click="
                              myLiteratureReplyComments(item.id, val.comment_id)
                            "
                            >发布</el-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    display: flex;
                    align-items: flex-end;
                    padding: 20px;
                    justify-content: space-between;
                  "
                >
                  <textarea
                    class="reply"
                    placeholder="写下你的评论："
                    v-model="commentcont"
                  ></textarea>
                  <el-button type="danger" @click="myWXPostComments"
                    >发布</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="artright">
            <div class="rtitle">相关文献</div>
            <p
              class="wenxians"
              v-for="items in relation"
              :key="items.id"
              @click="literature(items.id)"
            >
              {{ items.wx_name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!--<script src="../public/layui/layui.js"></script>-->
<script>
import {
  LiteratureDetails,
  LiteratureThumbs,
  QXLiteratureThumbs,
  LiteratureComments,
  LiteratureReplyComments,
  LiteratureReply,
  LiteratureComment,
  membersRight,
  WXPostComments,
} from "../../API/homepage/document/document.js";
import { Literature } from "../../API/homepage/policy.js";
import { downloadFile } from "@/API/orderstatus";
export default {
  name: "Article_detail",
  components: {},
  data() {
    return {
      dialogVisible: false,
      did: "",
      //文章详情
      myzan: [],
      zantwo: [],
      zan: 0,
      url: "",
      commentcont: "",
      CommentsL: "",
      status: 0,
      hf: "初始",
      hf2: "初始",
      hf22: "初始",
      replycont: "",
      articlelist: {},
      //用户列表
      user: [],
      //评论数
      count: "",
      //相关文献
      relation: [],
    };
  },
  mounted() {
    this.did = this.$route.query.did;
    this.myLiteratureDetails();
    this.myLiteratureComments();
    this.LiteratureList();
    if (sessionStorage.getItem("sid")) {
      this.status = 1;
    }
    this.CommentsLists();
  },
  methods: {
    membersRight() {
      if (sessionStorage.getItem("sid")) {
        membersRight(localStorage.getItem("ZZId"), "是", "否").then((res) => {
          if (res.data.code == -1) {
            this.$message.warning("下载文献次数已用尽");
          } else {
            this.downloadAttach();
          }
        });
      } else {
        this.dialogVisible = true;
      }
    },
    //发布评论
    myWXPostComments() {
      if (this.commentcont.length == 0) {
        this.$message.warning("评论内容不能为空");
      } else {
        WXPostComments(
          this.did,
          sessionStorage.getItem("sid"),
          this.commentcont
        ).then((res) => {
          console.log(this.did);
          if (res.data.code == 0) {
            this.commentcont = "";
            this.$message.success("发布成功");
            this.CommentsLists();
            this.myLiteratureComments();
          }
        });
      }
    },
    toLogin() {
      this.$router.push({
        path: "/homePage",
      });
    },
    towenx() {
      this.$router.push({
        path: "/document",
      });
    },
    myLiteratureDetails() {
      //查询文章详情
      LiteratureDetails(this.did, sessionStorage.getItem("sid")).then((res) => {
        console.log(res);
        this.articlelist = res.data.data;
      });
    }, //下载附件
    downloadAttach() {
      this.dialogVisible = false;
      let Path = this.articlelist.enclosure;
      console.log(Path);
      downloadFile(Path);
    }, //点赞
    support() {
      if (sessionStorage.getItem("sid")) {
        this.dialogVisible = false;
        LiteratureThumbs(this.did, 2, sessionStorage.getItem("sid")).then(
          (res) => {
            this.zan = 2;
            console.log(res);
            this.myLiteratureDetails();
          }
        );
      } else {
        this.dialogVisible = true;
      }
    }, //取消点赞
    qxsupport() {
      if (sessionStorage.getItem("sid")) {
        this.dialogVisible = false;
        LiteratureThumbs(this.did, 2, sessionStorage.getItem("sid")).then(
          (res) => {
            this.zan = 0;
            console.log(res);
            this.myLiteratureDetails();
          }
        );
      } else {
        this.dialogVisible = true;
      }
    },
    //文献评论数
    myLiteratureComments() {
      LiteratureComments(this.did).then((res) => {
        console.log(res);
        this.count = res.data.data.idd;
      });
    },
    myLiteratureReplyComments(commentid, toid) {
      //回复评论
      console.log(toid);
      LiteratureReplyComments(
        commentid,
        sessionStorage.getItem("sid"),
        toid,
        this.replycont
      ).then((res) => {
        console.log(res);
        if (res.data.code == 0) {
          this.replycont = "";
          this.$message.success(res.data.data);
          this.CommentsLists();
        } else {
          this.$message.error(res.data.data);
        }
      });
    },
    // 相关文献
    LiteratureList() {
      Literature(1, 10, this.did).then((res) => {
        this.relation = res.data.data;
      });
    },
    //相关文献详情
    literature(e) {
      LiteratureDetails(e).then((res) => {
        console.log(res);
        this.articlelist = res.data.data;
      });
    },

    CommentsLists() {
      //查看文献评论
      this.url = this.URL;
      LiteratureComment(this.$route.query.did, sessionStorage.getItem("sid"))
        .then((res) => {
          console.log(res);
          this.CommentsL = res.data.data;
          return this.CommentsL;
        })
        .then((res) => {
          for (var i = 0; i < res.length; i++) {
            var j = -1;
            // this.zantwo[i]=[]
            this.$set(this.zantwo, i, []);
            console.log(res[i].id);
            LiteratureReply(res[i].id, sessionStorage.getItem("sid")).then(
              (res) => {
                j++;
                // this.CommentsL[j]['replylist'] = res.data.data
                this.$set(this.CommentsL[j], "replylist", res.data.data);
                return this.CommentsL;
              }
            );
          }
          console.log((this.user = this.CommentsL));
        });
    },
    //回复
    replay(e) {
      this.hf = e;
    },
    noreplay() {
      this.hf = "初始";
    }, //回复
    replay2(index, i) {
      this.hf2 = "初始";
      this.hf22 = "初始";
    },
    noreplay2(index, i) {
      this.hf2 = index;
      this.hf22 = i;
    }, // 判断是否存在在数组中
    hasExist(index) {
      const set = new Set(this.myzan);
      if (set.has(index)) {
        return true;
      } else {
        return false;
      }
    },
    // 判断是否存在在数组中
    hasExist2(i, index) {
      if (this.zantwo[index].indexOf(i) == -1) {
        return false;
      } else {
        return true;
      }
    },
    // 一级点赞
    myGiveTheThumb(id, tp, index) {
      this.myzan.push(index);
      LiteratureThumbs(id, 0, sessionStorage.getItem("sid")).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("点赞成功");
          this.CommentsLists();
        }
      });
    },
    // 取消一级点赞
    myQXThumb(id, tp, i, index) {
      let wz = this.myzan.indexOf(index);
      this.myzan.splice(wz, 1);
      LiteratureThumbs(id, 0, sessionStorage.getItem("sid")).then((res) => {
        console.log(res);
        this.CommentsLists();
      });
    },
    // 二级点赞
    myGiveTheThumb2(id, tp, i, index) {
      this.zantwo[index].push(i);
      LiteratureThumbs(id, 1, sessionStorage.getItem("sid")).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("点赞成功");
          this.CommentsLists();
        }
      });
    },
    // 取消二级点赞
    myQXThumb2(id, tp, i, index) {
      let wz = this.zantwo[index].indexOf(i);
      this.zantwo[index].splice(wz, 1);
      LiteratureThumbs(id, 1, sessionStorage.getItem("sid")).then((res) => {
        console.log(res);
        this.CommentsLists();
      });
    },
    gologin() {
      this.dialogVisible = false;
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped="scoped">
.bg {
  background: #f7f7f7;
  padding-top: 2px;
}

.item {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
}

.bread {
  color: #999999;
  font-size: 14px;
}

.bread span {
  color: #000;
}

.them {
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #141414;
  margin: 5px 0;
  width: 100%;
  text-align: center;
}

.title,
.updete_time {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  margin: 10px 0;
  width: 100%;
  text-align: center;
}
.title span {
  margin: 0 10px;
}
.wcont {
  display: flex;
}

.artleft {
  flex: 3;
}

.artright {
  flex: 1;
  margin-top: 20px;
  background-color: #fff;
  margin-left: 20px;
  height: 390px;
  padding: 20px;
  box-sizing: border-box;
}

.rtitle {
  height: 50px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 600;
  color: #141414;
  line-height: 50px;
  border-bottom: 1px solid #f0f0f0;
}

.artright p {
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
  margin: 10px 0;
  overflow: hidden;
}

.cont {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
  flex: 1;
}

.xj {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 17px;
  box-sizing: border-box;
  padding-left: 20px;
}
.xj .key span {
  font-weight: bold;
  color: #333;
}
.xj .key {
  display: flex;
  line-height: 22px;
}
.download {
  display: flex;
}

.download img {
  width: 12px;
}

.d {
  margin: 0 3px;
  cursor: pointer;
}

.comment {
  padding: 20px;
  background-color: #fff;
  margin-top: 40px;
}

.commentnologin {
  padding: 20px;
  background-color: #fff;
  margin-top: 40px;
  color: #999;
  text-align: center;
}

.commentnologin a {
  color: #0000ff;
  cursor: pointer;
  margin: 0 5px;
}

.commentlist {
  padding-bottom: 10px;
  border-bottom: 1px solid #ebebeb;
}

.commentlist2 {
  padding-left: 40px;
  margin-top: 10px;
}

.ctitle {
  line-height: 50px;
}

.ctitle span {
  color: #999;
  float: right;
  font-size: 14px;
}

.user {
  color: #666666;
  font-size: 14px;
}

.user img {
  width: 34px;
  height: 34px;
  border-radius: 17px;
  vertical-align: middle;
  margin-right: 10px;
}

.sj {
  font-size: 14px;
  margin-left: 41px;
}

.sj .ico {
  width: 12px;
  height: 12px;
}

.sj i {
  margin-right: 20px;
  cursor: pointer;
}

.zh {
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb;
}

.reply {
  padding: 10px;
  width: 85%;
  height: 100px;
  background: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  resize: none;
}

.bread span {
  cursor: pointer;
}
.bread span:hover {
  color: red;
}
.wenxians {
  cursor: pointer;
}
.wenxians:hover {
  color: red;
}
</style>
