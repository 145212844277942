<template>
  <div style="padding: 20px; background-color: #fff">
    <el-collapse v-for="(item,index) in textlist" :key="index">
		  <el-collapse-item :title="item.problem" name="1">
			 <p v-html='item.answer'> </p>
			  <p></p>
		  </el-collapse-item>
		</el-collapse>
  </div>
</template>

<script>
  import { commonQuestion } from "../../../API/footer.js"
  export default {
	
		data() {
			return {
		
        textlist:[],
			}
		},
		mounted() {
      this.copyrightNoticelist()
		},
		methods: {
			copyrightNoticelist() {
			  commonQuestion().then(res =>{
       console.log(res);
          this.textlist = res.data.data
          console.log( this.textlist);
        })

			},
    }
  }
</script>


<style>
</style>
