<template>
	<div style="padding: 20px;  background-color: #fff;">
    <div v-for="(item,index) in  initData" :key="index">
     <p class="zzzs" >{{item.name}}</p>
		<img :src="url + item.content" alt="" class="zs">
    </div>
		
	</div>
</template>

<script>
import { selectGlory } from "../../../API/footer.js"
	export default {
	
		data() {
			return {
        url:'',
        initData:[],
			}
		},
		mounted() {
      this.url = this.URL
      this.companyIntroduce()
		},
		methods: {
			companyIntroduce() {
			  selectGlory(4).then(res =>{
          console.log(res.data.data);
          this.initData = res.data.data
          console.log(this.url+this.initData[0].content);
        })

			},
		}
	}
</script>

<style>
	.zzzs{
		
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #141414;
		line-height:40px;
	}
	.zs{
    width: 100%;
    height: 300px;
    text-align: center;
		border: 1px dashed #e6e6e6;
	}
  
</style>
