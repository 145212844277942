<template>
  <div style="background-color: #fff">
    <div class="acont">
      <!-- <div class="header"></div> -->
      <div class="toppicd">
        <img src="../../../Newimg/wenxian.jpg" alt="" />
      </div>
      <div class="w" style="margin-top: -100px">
        <div class="box">
          <div class="search">
            <el-input
              placeholder="请输入内容"
              v-model="formdata.keywords"
              class="input-with-select"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="newLiterature(type_id)"
              ></el-button>
            </el-input>
          </div>
          <div class="page1" v-if="show">
            <div
              :class="type_id == '' ? 'active' : ''"
              @click="newLiterature('')"
            >
              全部
            </div>
            <div
              :class="type_id == item.id ? 'active' : ''"
              v-for="(item, index) in academic.slice(0, 22)"
              :key="index"
              @click="newLiterature(item.id)"
            >
              {{ item.types }}
            </div>
          </div>
          <div class="page1" v-else>
            <div
              @click="newLiterature('')"
              :class="type_id == '' ? 'active' : ''"
            >
              全部
            </div>
            <div
              :class="type_id == item.id ? 'active' : ''"
              v-for="(item, index) in academic"
              :key="index"
              @click="newLiterature(item.id)"
            >
              {{ item.types }}
            </div>
          </div>
          <div class="stren" v-if="show">
            <span @click="stren">
              展开
              <img src="../../assets/slices/down.png" alt="" class="ico" />
            </span>
          </div>
          <div class="stren" v-else>
            <span @click="stren">
              收起
              <img src="../../assets/slices/up.png" alt="" class="ico" />
            </span>
          </div>
        </div>
        <div class="document-body">
          <div class="document">
            <div class="dcont">
              <artlist
                :title="document[0].theme"
                :artlist="document[0].doculist"
              ></artlist>
              <div class="page">
                <el-pagination
                  class="fy"
                  small
                  @size-change="handleSizeChange"
                  :page-size="pagesize"
                  :page-sizes="[9, 15, 20]"
                  layout="total, sizes, prev, pager, next, jumper"
                  @current-change="current_change"
                  :total="total"
                  background
                >
                </el-pagination>
              </div>
            </div>
            <!-- <router-link to="/article"
            ><div class="more">查看更多</div></router-link> -->
          </div>
          <div class="document-right">
            <div class="document-options">
              <div class="options-item" @click="gotougao">
                <img :src="require('@/assets/image/tougao.png')" alt="" />
              </div>
              <div class="options-item" @click="goqikan">
                <img :src="require('@/assets/image/ruzhu.png')" alt="" />
              </div>
            </div>
            <div class="document-hot">
              <div class="document-hot-title">{{ document[2].theme }}</div>
              <div class="document-hot-list">
                <div
                  class="document-hot-item"
                  v-for="(item, index) in document[2].doculist"
                  :key="index"
                  @click="showdetail(item.id)"
                >
                  <div
                    class="document-hot-rank"
                    :class="
                      index == 0
                        ? 'color1'
                        : index == 1
                        ? 'color2'
                        : index == 2
                        ? 'color3'
                        : ''
                    "
                  >
                    {{ index + 1 }}
                  </div>
                  <div class="document-item-title">
                    <div
                      class="document-item-title"
                      style="padding: 0; border: none"
                    >
                      {{ item.wx_name }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="document-ad">
              <el-carousel height="150px" arrow="never">
                <el-carousel-item
                  v-for="(item, index) in bannerrList"
                  :key="index"
                >
                  <img
                    :src="url + item.cover"
                    @click="golink(item.link)"
                    alt=""
                    class="carousel-img"
                  />
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<!--<script src="../public/layui/layui.js"></script>-->
<script>
import { Academic, Include } from "../../API/homepage/document/document.js";
import Topic from "@/components/Topics.vue";
import artlist from "@/components/Artlist.vue";
import { Carousel } from "../../API/homepage/policy.js";
import { Literature } from "../../API/homepage/document/document.js";
export default {
  name: "Zxwx",
  components: {
    artlist,
    Topic,
    Literature,
  },
  data() {
    return {
      //文献列表数据
      show: true,
      total: 0, //默认数据总数
      pagesize: 9, //每页的数据条数
      currentPage: 1, //默认开始页面
      url: "",
      document: [
        { theme: "最新文献", doculist: [] },
        {
          theme: "精品文献",
          doculist: [],
        },
        {
          theme: "人气文献",
          doculist: [],
        },
      ],
      formdata: {
        keywords: "",
      },
      academic: [],
      type_id: "",
      bannerrList: [],
      //书籍列表数据
      //第一次显示的30个标签
    };
  },
  mounted() {
    this.myAcademic();
    this.newLiterature(this.type_id);
    this.bestLiterature();
    this.popLiterature();
    this.CarouseList();
    this.url = this.URL;
  },
  methods: {
    CarouseList() {
      Carousel(2).then((res) => {
        this.bannerrList = res.data.data;
      });
    },
    golink(url) {
      if (url == "") {
        return;
      }
      window.location.href = url;
    },
    handleSizeChange(val) {
      console.log(val);
      this.pagesize = val;
      this.newLiterature(this.type_id);
    },
    current_change: function (currentPage) {
      this.currentPage = currentPage;

      this.newLiterature(this.type_id);
    },
    gotougao() {
      this.$router.push("/Pending/contribute");
    },
    goqikan() {
      this.$router.push("/enter?now=2");
    },
    showdetail(e) {
      console.log(e);
      this.$router.push({
        path: "/article/detail",
        query: {
          did: e,
        },
      });
    },
    newLiterature(id) {
      //最新
      this.type_id = id;
      Literature(
        this.currentPage,
        this.pagesize,
        id,
        "",
        "",
        "",
        "",
        0,
        this.formdata.keywords
      ).then((res) => {
        console.log(res, "最新接口=======");
        this.document[0].doculist = res.data.data;
        this.total = res.data.total;
      });
    },
    bestLiterature() {
      //精
      Literature(1, 6, "", "", "", "", "", "", this.formdata.keywords).then(
        (res) => {
          console.log(res, "精品接口======");
          this.document[1].doculist = res.data.data;
        }
      );
    },
    popLiterature() {
      //人气
      Literature(1, 6, "", "", "", "", "", 2, this.formdata.keywords).then(
        (res) => {
          console.log(res, "人气接口====");
          this.document[2].doculist = res.data.data;
        }
      );
    },
    myAcademic() {
      Academic().then((res) => {
        console.log(res);
        this.academic = res.data.data;
      });
    },
    stren() {
      this.show = !this.show;
    },
    gotodoc(e) {
      this.$router.push({ path: "/article", query: { acd: e } });
    },
  },
};
</script>
<style >
.el-carousel__indicator .el-carousel__button {
  background: #959595;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}
.el-carousel__indicator.is-active .el-carousel__button {
  background: white;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 150px;
  text-align: center;
  margin: 0;
}
.search .el-input-group .el-input__inner {
  border: none !important;
  height: 38px;
  border-radius: 20px;
}
.search .el-input-group .el-input-group__append {
  background: none;
  border: none;
  color: #75a1ea;
  font-size: 20px;
  font-weight: bold;
}
</style>
<style  scoped>
.document-ad {
  background: #e5e5e5;
  border-radius: 16px;
  margin-top: 20px;
}
.document-hot {
  background: #fff;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 20px 16px;
  border-radius: 12px;
}
.document-hot .document-hot-title {
  color: #333;
  font-weight: bold;
  font-size: 18px;
}
.document-hot .document-hot-list {
  margin-top: 16px;
}
.document-hot .document-hot-list .document-hot-item {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  cursor: pointer;
  padding-top: 16px;
}
.document-hot-rank {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d2d2d2;
  color: #fff;
  margin-right: 16px;
}
.document-item-title {
  flex: 1;
  font-size: 14px;
  color: #a2a2a2;
  border-bottom: 1px solid #e5e5e5;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 22px;
  height: 44px;
  padding-bottom: 16px;
  transition: all 0.2s;
}
.document-item-title:hover {
  color: #535353;
  height: 100%;
  display: block;
}
.color1 {
  background: #fa9999;
}
.color2 {
  background: #f7b697;
}
.color3 {
  background: #a5d8a1;
}

.search {
  display: flex;
  width: 70%;
  height: 40px;
  border: 1px solid #e5e5e5;
  border-radius: 20px;
  box-sizing: border-box;
  margin-bottom: 30px;
  margin: 0 auto;
  margin-bottom: 16px;
}
.header {
  width: 100%;
  height: 200px;
  background: #6c9be9;
}
.acont {
  position: relative;
  background: #f7f7f7;
  padding-bottom: 30px;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
  color: transparent;
}
.box {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 30px 0px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
}
.page1 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.page1 .active {
  background: #409eff;
  color: #fff;
}
.page1 div {
  margin: 5px;
  text-align: center;
  height: 34px;
  line-height: 34px;
  background: #f7f7f7;
  border-radius: 17px;
  box-sizing: border-box;
  padding: 0 18px;
  font-size: 14px;
  cursor: pointer;
}
.ico {
  width: 12px;
}
.stren {
  text-align: center;
  line-height: 60px;
}
.stren span {
  cursor: pointer;
}
.document {
  width: 74%;
  left: 0;
  box-sizing: border-box;
  background: #fff;
  padding: 30px;
  border-radius: 16px;
}
.document-right {
  width: 24%;
}
.document-right .document-options .options-item img {
  width: 100%;
  margin-top: 20px;
  cursor: pointer;
}
.document-body {
  display: flex;
  width: 100%;
  margin: 30px auto;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 30px 0;
  overflow: hidden;
  position: relative;
}
.more {
  width: 100px;
  height: 40px;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid #ff1918;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #ff1918;
  margin-top: 20px;
  cursor: pointer;
}
.page {
  height: 100px;
  display: flex;
  width: 100%;
  margin: 0 auto;
  line-height: 100px;
  margin-top: 30px;
  justify-content: center;
}
.carousel-img {
  width: 100%;
  height: 100%;
}
.toppicd {
  width: 100%;
}
.toppicd img {
  width: 100%;
}
._v-container > ._v-content > .pull-to-refresh-layer {
  width:100%;
  height:120px;
  margin-top:-120px;
  text-align:center;
  font-size:32px;
  color:#AAA;
  float:left;
  top:0;
  left:0;
}
</style>
