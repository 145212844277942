import request from '@/utils/request'
import qs from 'qs'
// 退出登录
export function exits () {
  
  return request({
    url: '/facilitator/chayue',
    method: 'post',
  
  })
}
