<template>
  <div class="w" style="padding: 20px;margin:20px auto; background-color: #fff">
    <p class="pictitle">期刊入驻流程</p>
    <ul class="river">
      <li>
        <img src="../../../assets/image/enter/register.png" alt="" />
        <p>注册账号</p>
      </li>
      <li>
        <img src="../../../assets/image/enter/jtleft.png" alt="" />
      </li>
      <li>
        <img src="../../../assets/image/enter/company.png" alt="" />
        <p>企业实名</p>
      </li>
      <li>
        <img src="../../../assets/image/enter/jtleft.png" alt="" />
      </li>
      <li>
        <img src="../../../assets/image/enter/appli.png" alt="" />
        <p>提交申请</p>
      </li>
    </ul>
    <div class="down">
      <img src="../../../assets/image/enter/jtdown.png" alt="" />
    </div>
    <ul class="river">
      <li>
        <img src="../../../assets/image/enter/cg.png" alt="" />
        <p>入驻成功</p>
      </li>
      <li>
        <img src="../../../assets/image/enter/jtright.png" alt="" />
      </li>
      <li>
        <img src="../../../assets/image/enter/qs.png" alt="" />
        <p>签署协议</p>
      </li>
      <li>
        <img src="../../../assets/image/enter/jtright.png" alt="" />
      </li>
      <li>
        <img src="../../../assets/image/enter/sh.png" alt="" />
        <p>资质审核</p>
      </li>
    </ul>
    <p
      class="dl"
      v-for="(item, index) in textlist"
      :key="index"
      v-html="item.content"
    ></p>
  </div>
</template>

<script>
import { selectQiKanSettleIn } from "../../../API/footer.js";
export default {
  data() {
    return {
      textlist: [],
    };
  },
  mounted() {
    this.companyIntroduce();
  },
  methods: {
    companyIntroduce() {
      selectQiKanSettleIn().then((res) => {
        this.textlist = res.data.data;
        // console.log(res,'期刊入驻');
      });
    },
  },
};
</script>

<style>
.river {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
  margin: 0 auto;
  position: relative;
}
.river li {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.down {
  width: 60%;
  margin: 10px auto;
  display: flex;
  box-sizing: border-box;
  padding-right: 24px;
  justify-content: flex-end;
}
.pictitle {
  font-weight: 800;
  display: block;
  margin: 20px 0;
}
.dl {
  margin: 20px 0;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 26px;
}
</style>
