<template>
	<div style="padding: 20px;  background-color: #fff;">
	<el-collapse v-for="(item,index) in initData" :key="index">
	  <el-collapse-item :title="item.name" name="1">
	     <div class="photo" >
	    	<img :src=" url+ item.content" alt="" class="image">
	  
	    </div>
	  </el-collapse-item>
	  

	 </el-collapse>
	</div>
</template>

<script>
 import { selectActivity } from "../../../API/footer.js"
	export default {
	
		data() {
			return {
        url:'',
        initData:[],
			}
		},
		mounted() {
      this.url = this.URL
      this.companyIntroduce()
		},
		methods: {
			companyIntroduce() {
			  selectActivity(3).then(res =>{
          console.log(res);
          this.initData = res.data.data
          console.log(this.url+this.initData[0].content);
        })

			},
		}
	}
</script>

<style>
	.photo{
		display: flex;
		flex-wrap: wrap;
	}
	.photo img{
		width: 45%;
    height: 200px;
		margin: 10px;
    margin: auto;
	}
</style>
