<template>
  <div>
    <div class="back">
      <div class="comments">
        <!-- z1 -->
        <div class="detail">
          <div class="navigation">
            <span @click="toLogin">首页</span>
            <span @click="towenx"> - {{ them }} - </span>
            <span>{{ qkdetail.name }}</span>
          </div>
          <div style="padding: 20px; position: relative">
            <div class="qktitle">{{ qkdetail.name }}</div>
            <div class="qkcontent">
              <div class="qkcontent-img">
                <img :src="imgSrc" />
                <div class="azybtn" v-show="usercode">
                  <el-button
                    class="b1"
                    @click="
                      contribute(
                        qkdetail.qikan_id,
                        qkdetail.name,
                        qkdetail.include
                      )
                    "
                    >投稿</el-button
                  >
                  <el-button
                    v-if="qkdetail.Collect == 0"
                    class="b2"
                    @click="clickazybtn(qkdetail.qikan_id)"
                    >加入收藏</el-button
                  >
                  <el-button
                    class="b2"
                    v-if="qkdetail.Collect == 1"
                    @click="qxclickazybtn(qkdetail.qikan_id)"
                    >取消收藏</el-button
                  >
                  <el-dialog
                    title="提示"
                    :visible.sync="dialogVisible"
                    width="30%"
                  >
                    <span>登录后投稿或收藏</span>
                    <span slot="footer" class="dialog-footer">
                      <el-button @click="dialogVisible = false"
                        >取 消</el-button
                      >
                      <el-button type="primary" @click="gologin"
                        >现在去登录</el-button
                      >
                    </span>
                  </el-dialog>
                </div>
              </div>
              <div class="qkcontent-scrp">
                <ul>
                  <li>
                    <div>
                      <p>
                        <span class="qkbt">期次：</span
                        ><span>{{ qkdetail.issue }}</span>
                      </p>
                      <p>
                        <span class="qkbt">国内刊号：</span
                        ><span>{{ qkdetail.guoneikanhao }}</span>
                      </p>
                      <p>
                        <span class="qkbt">期刊主编：</span
                        ><span>{{ qkdetail.qikaneditor }}</span>
                      </p>
                      <p>
                        <span class="qkbt">邮发代号：</span
                        ><span>{{ qkdetail.postnumber }}</span>
                      </p>
                      <p>
                        <span class="qkbt">发行地点：</span
                        ><span>{{ qkdetail.publishlocation }}</span>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <p>
                        <span class="qkbt">截稿日期：</span
                        ><span class="times">{{ qkdetail.deadline }}</span>
                      </p>
                      <p>
                        <span class="qkbt">国际刊号：</span
                        ><span>{{ qkdetail.issn }}</span>
                      </p>
                      <p>
                        <span class="qkbt">审稿时间：</span
                        ><span>{{ qkdetail.checktime }}</span>
                      </p>
                      <p>
                        <span class="qkbt">创刊时间：</span
                        ><span>{{ qkdetail.createtime }}</span>
                      </p>
                      <p>
                        <span class="qkbt">邮政编码：</span
                        ><span>{{ qkdetail.postalcode }}</span>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div>
                      <p>
                        <span class="qkbt">预计出刊时间：</span
                        ><span class="times">{{ qkdetail.publishtime }}</span>
                      </p>
                      <p>
                        <span class="qkbt">出版周期：</span
                        ><span>{{ qkdetail.publishcycle }}</span>
                      </p>
                      <p>
                        <span class="qkbt">期刊级别：</span
                        ><span>{{ qikanlevels }}</span>
                      </p>
                      <p>
                        <span class="qkbt">期刊语言：</span
                        ><span>{{ qkdetail.qikanlanguage }}</span>
                      </p>
                      <p>
                        <span class="qkbt">期刊类别：</span
                        ><span>{{ qkdetail.types }}</span>
                      </p>
                    </div>
                  </li>
                </ul>
                <div class="scrip-bottom">
                  <p>
                    <span class="qkbt">主管单位：</span
                    ><span>{{ qkdetail.competentunit }}</span>
                  </p>
                  <p>
                    <span class="qkbt">主办单位：</span
                    ><span>{{ qkdetail.sponsor }}</span>
                  </p>
                  <p>
                    <span class="qkbt">期刊收录：</span
                    ><span>{{ this.include }}</span>
                  </p>
                  <p>
                    <span class="qkbt">期刊荣誉：</span
                    ><span>{{ qkdetail.qikanhonour }}</span>
                  </p>
                  <p>
                    <span class="qkbt">杂志栏目：</span
                    ><span>{{ qkdetail.journal }}</span>
                  </p>
                  <p>
                    <span class="qkbt">影响因子：</span
                    ><span>{{ qkdetail.yingxiangyinzi }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- z2 -->
        <div class="intro">
          <div class="th">
            <h3 @click="clickqk(1)" :class="qk == 1 ? 'actives' : ''">
              期刊简介
            </h3>
            <h3 @click="clickqk(2)" :class="qk == 2 ? 'actives' : ''">
              投稿要求
            </h3>
          </div>
          <div class="txt" v-show="qk == 1">
            {{ qkdetail.qikanintroduction }}
          </div>
          <div class="txt" v-show="qk == 2">
            {{ qkdetail.requirement }}
          </div>
        </div>
        <!-- z3 -->
        <div v-if="showremark">
          <div class="remarks">
            <h3>{{ comment }}条评论</h3>
            <div
              class="wenxian"
              v-for="(item, index) in CommentsL"
              :key="index"
            >
              <div class="userp">
                <img :src="url + item.headimg" alt="" />
                <span>{{ item.editor }}</span>
                <i>{{ item.createtime }}</i>
              </div>
              <p>{{ item.comment }}</p>
              <div class="zan">
                <div
                  @click="myGiveTheThumb(item.id, 0, index)"
                  v-if="item.type == 0"
                >
                  <img src="../../assets/image/icon/zan.png" alt="" /><span>{{
                    item.praise
                  }}</span>
                </div>
                <div
                  @click="myQXThumb(item.id, 0, index)"
                  v-if="item.type == 1"
                >
                  <img src="../../assets/image/yizan.png" alt="" /><span>{{
                    item.praise
                  }}</span>
                </div>
                <div>
                  <img src="../../assets/image/icon/back.png" alt="" />
                  <span
                    v-if="replyone != index"
                    style="cursor: pointer"
                    @click="replylist(index)"
                    >回复</span
                  >
                  <span
                    v-if="replyone == index"
                    style="cursor: pointer"
                    @click="noreplylist()"
                    >取消回复</span
                  >
                </div>
              </div>
              <div class="textareas" v-if="replyone == index">
                <el-input
                  class="z"
                  type="textarea"
                  :rows="2"
                  :placeholder="'回复' + item.editor"
                  v-model="ReplyComments"
                >
                </el-input>
                <div
                  class="fabu"
                  @click="ReplyCommentsazybtn(item.id, item.author_id)"
                >
                  发布
                </div>
              </div>
              <div class="huifu">
                <div class="wenxian" v-for="(val, i) in item.list" :key="i">
                  <div class="userp">
                    <img :src="url + val.headimg" alt="" />
                    <span>{{ val.editor }}</span>
                    <span>回复</span>
                    <span>{{ val.toeditor }}</span>
                    <i>{{ val.createtime }}</i>
                  </div>
                  <p>{{ val.COMMENT }}</p>
                  <div class="zan">
                    <div
                      @click="myGiveTheThumb2(val.id, 1, i, index)"
                      v-if="val.type == 0"
                    >
                      <img src="../../assets/image/icon/zan.png" alt="" /><span
                        >{{ val.praise }}</span
                      >
                    </div>
                    <div
                      @click="myQXThumb2(val.id, 1, i, index)"
                      v-if="val.type == 1"
                    >
                      <img src="../../assets/image/yizan.png" alt="" /><span>{{
                        val.praise
                      }}</span>
                    </div>

                    <div>
                      <img src="../../assets/image/icon/back.png" alt="" />
                      <span
                        v-if="replytwo == index && replyt == i"
                        @click="noreplytwolist(index, i)"
                        style="cursor: pointer"
                        >取消回复</span
                      >
                      <span
                        v-else
                        style="cursor: pointer"
                        @click="replytwolist(index, i)"
                        >回复</span
                      >
                    </div>
                  </div>
                  <div
                    class="textareas"
                    v-if="replytwo == index && replyt == i"
                  >
                    <el-input
                      class="z"
                      type="textarea"
                      :rows="2"
                      :placeholder="'回复' + val.editor"
                      v-model="ReplyComments"
                    >
                    </el-input>
                    <div
                      class="fabu"
                      @click="ReplyCommentsazybtn(item.id, val.comment_id)"
                    >
                      发布
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="textareas fabu2">
              <el-input
                class="z"
                type="textarea"
                :rows="2"
                placeholder="写下你的评论"
                v-model="textarea"
              >
              </el-input>
              <div class="fabu" @click="PostCommentList()">发布</div>
            </div>
          </div>
        </div>
        <div v-else class="nologin">
          <span class="logins" @click="loginList">登录</span>后查看评论及回复！
        </div>
      </div>

      <div class="correlation">
        <div class="beijing">
          <div class="cortitle">相关期刊</div>
          <div class="corimg">
            <div
              v-for="item in magzine"
              :key="item.qikan_id"
              @click="see(item.qikan_id)"
            >
              <img :src="url + item.cover" alt="" />
              <span class="sese">{{ item.qk_name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { addfavorites, deleteaFvorites,resultMsg } from "../../API/ZZD/magzine.js";
import {
  JournalDetail,
  Comment,
  CommentsList,
  CommentReplys,
  PostComment,
  ReplyComment,
  GiveTheThumb,
  QXThumb,
  ClassificationList,
} from "../../API/homepage/policy.js";
export default {
  data() {
    return {
      qkid: "",
      myzan: [],
      zantwo: [],
      usercode: false,
      replyone: "初始",
      replytwo: "初始",
      replyt: "初始",
      dialogVisible: false,
      shoucang: 0,
      qk: 1,
      showremark: false,
      textarea: "",
      show: false,
      qkdetail: "",
      url: "", // 图片访问ip地址
      imgSrc: "",

      reply: false, // 0回复 1取消回复
      //期刊数据
      magzine: [],
      comment: "", //评论数
      CommentsListsw: [], // 评论信息
      CommentsL: [],
      CommentReplysLists: [], // 评论者信息
      ReplyComments: "", // 回复消息
      mids: "", //期刊id
      typeID: "", // 期刊类型id
      qikanlevels: "",
      them: "", // 推荐期刊的标题
      include: "", //include
    };
  },

  watch: {
    //监听路由变化
    '$route' (to, from) {
      if(to.name == 'postingdetails' || from.name == 'postingdetails') {
           alert(2);

      }
    }
  },
  methods: {
    toLogin() {
      this.$router.push({
        path: "/homePage",
      });
    },
    towenx() {
      this.$router.push({
        path: "/homePage",
      });
    },
    //   期刊
    clickqk(x) {
      this.qk = x;
    },
    // 点击登录
    loginList() {
      this.$router.push("/login");
    },
    // 点击相关期刊刷新期刊详情页面
    see(e) {
      this.mids = e;
      JournalDetail(this.mids, sessionStorage.getItem("sid")).then((res) => {
        this.qkdetail = res.data.data;
        this.imgSrc = this.URL + this.qkdetail.cover;
      });
    },
    //期刊详情信息
    JournalDetailList() {
      JournalDetail(this.mids, localStorage.getItem("ZZId")).then((res) => {
        this.qkdetail = res.data.data;
        this.include = res.data.data.include.trim().split(" "); //截取数组中的一个
        this.include.shift(); //删除第一项
        this.include = this.include.toString(); // 将结果转换为字符串
        this.imgSrc = this.URL + this.qkdetail.cover;
        if (this.qkdetail.qikanlevel == 1) {
          this.qikanlevels = "CSSCI南大核心期刊";
        } else if (this.qkdetail.qikanlevel == 2) {
          this.qikanlevels = "北大核心期刊";
        } else if (this.qkdetail.qikanlevel == 3) {
          this.qikanlevels = "CSCD核心期刊";
        } else if (this.qkdetail.qikanlevel == 4) {
          this.qikanlevels = "统计源期刊";
        } else if (this.qkdetail.qikanlevel == 5) {
          this.qikanlevels = "国家级期刊";
        } else if (this.qkdetail.qikanlevel == 6) {
          this.qikanlevels = "省级期刊";
        } else if (this.qkdetail.qikanlevel == 7) {
          this.qikanlevels = "SCI核心期刊";
        }
      });
    },
    // 评论条数
    Comments() {
      Comment(this.mids).then((res) => {
        console.log(res);
        this.comment = res.data.data.idd;
      });
    },
    // 查看一级评论
    CommentsLists() {
      CommentsList(this.mids, sessionStorage.getItem("sid"))
        .then((res) => {
          this.CommentsL = res.data.data;
          console.log(res.data.data[0].list, 55555);

          return this.CommentsL;
        })
        .then((res) => {
          for (var i = 0; i < res.data.data[0].list.length; i++) {
            var j = -1;
            this.zantwo[i] = [];
            this.$set(this.zantwo, i, []);
          }
          console.log((this.CommentsListsw = this.CommentsL));
        });
    },
    CommentReplysList(id) {
      console.log(id);
      CommentReplys(id).then((res) => {
        console.log(res);
        return res.data.data;
      });
    },
    // 期刊发布消息
    PostCommentList() {
      if(this.textarea==null || this.textarea==""){
      return   this.$message("请输入评论内容！");
      }
      PostComment(this.mids, sessionStorage.getItem("sid"), this.textarea).then(
        (res) => {
          if (res.data.code == "0") {
            this.$message(res.data.data);
            this.textarea = "";
            this.Comments();
            this.CommentsLists();
          } else {
            this.$massge(res.data.data);
          }
          console.log(res);
        }
      );
    },
    // 回复消息
    ReplyCommentsazybtn(comment_id, to_id) {
      console.log(comment_id, to_id);
      ReplyComment(
        comment_id,
        sessionStorage.getItem("sid"),
        to_id,
        this.ReplyComments
      ).then((res) => {
        if (res.data.code == "0") {
          this.$message(res.data.data);
          this.ReplyComments = "";
          this.Comments();
          this.CommentsLists();
        } else {
          this.$massge(res.data.data);
        }
      });
    },
    // 回复状态
    replylist(e) {
      this.replyone = e;
    },
    // 取消回复状态
    noreplylist() {
      this.replyone = "初始";
    },
    // 回复状态
    replytwolist(e, n) {
      this.replytwo = e;
      this.replyt = n;
    },
    // 取消回复状态
    noreplytwolist(e, n) {
      this.replyone = "初始";
      this.replyt = "初始";
    },
    // 判断是否存在在数组中
    hasExist(index) {
      const set = new Set(this.myzan);
      if (set.has(index)) {
        return true;
      } else {
        return false;
      }
    },
    // 判断是否存在在数组中
    hasExist2(i, index) {
      if (this.zantwo[index].indexOf(i) == -1) {
        return false;
      } else {
        return true;
      }
    },
    // 一级点赞
    myGiveTheThumb(id, tp, index) {
      this.myzan.push(index);
      GiveTheThumb(id, tp, sessionStorage.getItem("sid")).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("点赞成功");
          this.CommentsLists();
        }
      });
    },
    // 取消一级点赞
    myQXThumb(id, tp, i, index) {
      let wz = this.myzan.indexOf(index);
      this.myzan.splice(wz, 1);
      GiveTheThumb(id, tp, sessionStorage.getItem("sid")).then((res) => {
        console.log(res);
        this.CommentsLists();
      });
    },
    // 二级点赞
    myGiveTheThumb2(id, tp, i, index) {
      console.log(id, tp, i, index);

      GiveTheThumb(id, tp, sessionStorage.getItem("sid")).then((res) => {
        if (res.data.code == 0) {
          this.$message.success("点赞成功");
          // this.CommentsLists()
        }
      });
    },
    // 取消二级点赞
    myQXThumb2(id, tp, i, index) {
      let wz = this.zantwo[index].indexOf(i);
      this.zantwo[index].splice(wz, 1);
      GiveTheThumb(id, tp, sessionStorage.getItem("sid")).then((res) => {
        console.log(res);
        this.CommentsLists();
      });
    },
    //   收藏
    clickazybtn(qkid) {
      if (sessionStorage.getItem("sid")) {
        this.dialogVisible = false;
        let a_id = sessionStorage.getItem("sid");
        addfavorites(qkid, a_id).then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$message.success("收藏成功");
            this.JournalDetailList();
          }
        });
      } else {
        this.dialogVisible = true;
      }
    },
    qxclickazybtn(qkid) {
      //取消收藏
      if (sessionStorage.getItem("sid")) {
        this.dialogVisible = false;
        let a_id = sessionStorage.getItem("sid");
        deleteaFvorites(qkid, a_id).then((res) => {
          console.log(res);
          if (res.data.code == 0) {
            this.$message.success("取消收藏成功");
            this.JournalDetailList();
          }
        });
      } else {
        this.dialogVisible = true;
      }
    },
    contribute(id, name, title) {
      resultMsg(id).then((res) => {
        if (res.data.code == 0) {
          if (sessionStorage.getItem("sid")) {
            this.dialogVisible = false;
            sessionStorage.setItem("tgqk", id); // id
            sessionStorage.setItem("tgqkname", name); // 标题
            sessionStorage.setItem("include ", title); //本地存储期刊收录
            this.$router.push({
              path: "/Pending/contribute",
            });
          } else {
            this.dialogVisible = true;
          }
        } else {
          this.$message({
            showClose: true,
            message: "该期刊暂不支持投稿",
            type: "error",
          });
        }
      });
    },
    gologin() {
      this.dialogVisible = false;
      this.$router.push("/login");
    },
    // 相关期刊
    ClassificationLists() {
      ClassificationList(1, 6, this.typeID).then((res) => {
        console.log(res);
        this.magzine = res.data.data;
      });
    },
  },
  mounted() {
    if (!this.$route.query.title) {
      this.them = this.$route.query.them;
    } else if (this.$route.query.title) {
      this.them = this.$route.query.title;
    }
    this.mids = this.$route.query.mid;
    this.typeID = this.$route.query.qkId;
    this.url = this.URL;
    this.JournalDetailList();
    this.Comments();
    this.CommentsLists();
    this.ClassificationLists();
    if (sessionStorage.getItem("sid")) {
      this.showremark = true;
    }

    if (
      sessionStorage.getItem("scode") != 1 &&
      sessionStorage.getItem("scode") != 2 &&
      sessionStorage.getItem("scode") != 3
    ) {
      this.usercode = true;
    } else {
      this.usercode = false;
    }
  },
  created() {},
};
</script>

<style>
.back {
  width: 80%;
  margin: 20px auto 30px;
  display: flex;
  justify-content: space-between;
}

.comments {
  width: 73%;
}

.comments .detail {
  padding: 10px;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 30px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}

.detail .navigation {
  width: auto;
  height: 17px;
  font-size: 12px;
  color: #999999;
  line-height: 17px;
}

.detail .navigation span:nth-last-child(1) {
  color: #333333;
}

.detail .title {
  width: 112px;
  height: 40px;
  font-size: 28px;
  color: #141414;
  line-height: 40px;
  margin: 15px 0;
}

.detail .content {
  display: flex;
}

.detail .content img {
  width: 220px;
  margin-right: 30px;
}

.detail .content .contlist {
  width: 75%;
  /* background-color: skyblue; */
}

.el-row {
  margin-bottom: 15px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}

.el-row:last-child {
  margin-bottom: 0;
}

.el-row span:nth-child(1) {
  color: #666666;
}

.el-row span:nth-child(2) {
  color: #333333;
}

.nologin {
  padding: 20px;
  background-color: #fff;
  text-align: center;
  color: #999;
}

/* .contlist .conttop ul {

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.contlist .conttop ul li {
  width: 30%;
  height: 20px;
  margin-bottom: 13px;
  background-color: red;
  font-size: 14px;
} */

.azybtn {
  position: absolute;
  bottom: 50px;
  width: 100%;
}

.detail .azybtn .b1 {
  width: 90px;
  height: 40px;
  background-color: #ff1918;
  color: #fff;
}

.detail .azybtn .b2 {
  width: 98px;
  height: 40px;
  border: 1px solid #666666;
  color: #141414;
}

/* z2 */
.intro {
  margin: 20px 0;
  /* height: 175px; */
  background: #ffffff;
  border-radius: 4px;
}

.intro .th {
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid #e6e6e6;
  padding: 0 30px;
}

.intro .th h3 {
  cursor: pointer;
  display: inline-block;
  width: 64px;
  height: 62px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 22px;
  color: #999999;
  line-height: 62px;
}

.intro .th h3:nth-child(1) {
  margin-right: 20px;
}

.intro .th .actives {
  color: #141414;
  border-bottom: 2px solid #ff1918;
}

.intro .txt {
  /* height: 72px; */
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  padding: 20px 30px;
}

/* z3 */
.remarks {
  padding: 30px;
  height: 700px;
  background: #ffffff;
  border-radius: 4px;
}

.remarks h3 {
  width: 67px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 20px;
}

.remarks .wenxian {
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}

.remarks .wenxian .userp {
  display: flex;
  position: relative;
}

.remarks .wenxian .userp img {
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  /* background: red; */
}

.remarks .wenxian .userp span {
  display: inline-block;
  padding: 7px 10px;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
}

.remarks .wenxian .userp i {
  display: block;
  position: absolute;
  right: 0;
  padding: 7px 10px;
  line-height: 20px;
  font-size: 14px;
  color: #666666;
}

.remarks .wenxian p {
  display: inline-block;
  padding: 6px 0 12px 44px;
  font-size: 14px;
}

.remarks .wenxian .zan {
  display: flex;
  padding-left: 44px;
  font-size: 14px;
  color: #666666;
}

.remarks .wenxian .zan div {
  margin-right: 20px;
}

.remarks .wenxian .zan img {
  width: 14px;
  height: 15px;
  margin-right: 4px;
}

.huifu {
  margin-left: 44px;
  margin-top: 20px;
  border-top: 1px solid #ebebeb;
}

.huifu .wenxian {
  border: none;
}

.intro {
  margin: 20px 0;
  /* height: 175px; */
  background: #ffffff;
  border-radius: 4px;
}

.intro .th {
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid #e6e6e6;
  padding: 0 30px;
}

.intro .th h3 {
  cursor: pointer;
  display: inline-block;
  width: 64px;
  height: 62px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 22px;
  color: #999999;
  line-height: 62px;
}

.intro .th h3:nth-child(1) {
  margin-right: 20px;
}

.intro .th .actives {
  color: #141414;
  border-bottom: 2px solid #141414;
}

.intro .txt {
  /* height: 72px; */
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
  padding: 20px 30px;
}

/* z3 */
.remarks {
  padding: 30px;
  height: auto;
  background: #ffffff;
  border-radius: 4px;
}

.remarks h3 {
  width: 67px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #141414;
  line-height: 20px;
}

.remarks .wenxian {
  padding: 20px 0;
  border-bottom: 1px solid #ebebeb;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}

.remarks .wenxian .userp {
  display: flex;
  position: relative;
}

.remarks .wenxian .userp img {
  display: inline-block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  /* background: red; */
}

.remarks .wenxian .userp span {
  display: inline-block;
  padding: 7px 10px;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
}

.remarks .wenxian .userp i {
  display: block;
  position: absolute;
  right: 0;
  padding: 7px 10px;
  line-height: 20px;
  font-size: 14px;
  color: #666666;
}

.remarks .wenxian p {
  display: inline-block;
  padding: 6px 0 12px 44px;
  font-size: 14px;
}

.remarks .wenxian .zan {
  display: flex;
  padding-left: 44px;
  font-size: 14px;
  color: #666666;
}

.remarks .wenxian .zan div {
  margin-right: 20px;
}

.remarks .wenxian .zan img {
  width: 14px;
  height: 15px;
  margin-right: 4px;
}

.huifu {
  margin-left: 44px;
  margin-top: 20px;
  border-top: 1px solid #ebebeb;
}

.huifu .wenxian {
  border: none;
}

.textareas {
  /* display: flex;
    justify-content: space-between; */
  margin: 12px 0 0 44px;
  position: relative;
}

.textareas .fabu {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 80px;
  cursor: pointer;
  height: 40px;
  background: #ff1918;
  border-radius: 4px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}

.fabu2 {
  margin: 20px 0 0 8px;
}

.el-textarea__inner {
  width: 85%;
  background: #f7f7f7;
  border: 1px solid #cccccc;
  resize: none;
  height: 100px;
}

.correlation {
  width: 25%;
  /* background-color: blue; */
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}

.correlation .beijing {
  background-color: #fff;
  border-radius: 4px;
}

.correlation .cortitle {
  padding: 16px 20px;
  font-size: 16px;
  color: #141414;
  line-height: 22px;
  border-bottom: 1px solid #f0f0f0;
}

.correlation .corimg {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.corimg div {
  text-align: center;
  width: 45%;
  cursor: pointer;
  margin-bottom: 10px;
}

.corimg div span {
  display: block;
  margin: 8px 0;
}

.corimg div img {
  width: 100%;
  height: 150px;
  object-fit: contain;
}

.correlation {
  width: 25%;
  /* background-color: blue; */
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}

.correlation .beijing {
  background-color: #fff;
  border-radius: 4px;
}

.correlation .cortitle {
  padding: 16px 20px;
  font-size: 16px;
  color: #141414;
  line-height: 22px;
  border-bottom: 1px solid #f0f0f0;
}

.correlation .corimg {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.corimg div {
  text-align: center;
  width: 45%;
  cursor: pointer;
  margin-bottom: 10px;
}

.corimg div span {
  display: block;
  margin: 8px 0;
}

.corimg div img {
  width: 100%;
}

.logins {
  cursor: pointer;
  color: #0000cc;
}

.logins:hover {
  color: #ff1918;
}

.authortitle {
  padding: 20px;
  border-bottom: 1px solid #e6e6e6;
}

.qktitle {
  height: 80px;
  font-size: 28px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #141414;
  line-height: 80px;
}

.qkcontent {
  display: flex;
}

.qkcontent-img {
  width: 204px;
  position: relative;
}

.qkcontent-img img {
  width: 100%;
  height: 220px;
  object-fit: contain;
}

.qkcontent-scrp {
  flex: 3;
  margin-left: 20px;
}

.qkcontent-scrp ul {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}

.qkcontent-scrp ul li {
  margin-right: 10px;
  line-height: 30px;
}

.scrip-bottom {
  font-size: 14px;
  line-height: 30px;
}

.qkbt {
  color: #666;
}

.xxk {
  margin-top: 20px;
}

.qkjj {
  padding: 0 10px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 24px;
}

.navigation span {
  cursor: pointer;
}

.navigation span:hover {
  color: red;
}

.sese {
  width: auto;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #141414;
  line-height: 20px;
}

.times {
  width: 80px;
  font-size: 13px;
}
</style>
