<template>
  <div class="app">
    <div class="box box1">
      <div class="bg">
        <div class="mcont" v-if="type == 0">
          <div class="magpic">
            <div
              v-for="(item, index) in list"
              :key="index"
              @click="showmagzinedetail(item.qikan_id, item.qikantype_id)"
            >
              <img :src="`https://www.gckerui.com` + item.cover" alt="" />
              <span>{{ item.qk_name }}</span>
            </div>
          </div>
        </div>
        <div v-else>
          <artlist></artlist>
          <artlist
            title="智选文献"
            :artlist="list"
          ></artlist>
        </div>
      </div>

      <div class="page">
        <el-pagination
          class="fy"
          small
          @size-change="handleSizeChange"
          :page-size="pagesize"
          :page-sizes="[20, 40, 60]"
          layout="total, sizes, prev, pager, next, jumper"
          @current-change="current_change"
          :total="total"
          background
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { searchInfo } from "@/API/homepage/document/document.js";
import artlist from "@/components/Artlist.vue";
export default {
  data() {
    return {
      list: [],
      pagesize: 20,
      currentPage: 1,
      total: 0,
      keyward: "",
      type: 0,
    };
  },
  components: {
    artlist,
  },
  methods: {
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;
      this.myLiterature();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pagesize = val;
      this.myLiterature();
    },
    myLiterature() {
      searchInfo(this.currentPage, this.pagesize, this.keyward, this.type).then(
        (res) => {
          if(res.data.total==0){
          return   this.$message.error("没有检测到与"+this.keyward+"有关的信息");
          }else {
            this.list = res.data.data;
            this.total = res.data.total;
          }

        }
      );
    },
    showmagzinedetail(e, s) {
      this.$router.push({
        path: "/postingdetails",
        query: {
          mid: e,
          qkId: s,
        },
      });
    },
  },
  watch: {
    $route(to, from) {
      console.log("监听到路由变动了", this.$route);
      if (this.$route.query.keyward) {
        this.keyward = this.$route.query.keyward;
        this.type = this.$route.query.type;
        this.myLiterature();
      }
    },
  },
  mounted() {
    if (this.$route.query.keyward) {
      this.keyward = this.$route.query.keyward;
      this.type = this.$route.query.type;
      this.myLiterature();
    }
  },
};
</script>

<style>
.app {
  overflow: hidden;
}

.box {
  width: 80%;
  margin: 0 auto;
}

.box1 {
  margin: 30px auto 20px;
}

.mcont {
  margin-top: 30px;
}

.magpic {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.magpic div {
  text-align: center;
  width: 18%;
  cursor: pointer;
  margin: 0 5px 10px 5px;
}

.magpic div span {
  display: block;
  margin: 8px 0;
}

.magpic div img {
  width: 180px;
  height: 220px;
}

.page {
  height: 100px;
  width: 420px;
  margin: 0 auto;
  line-height: 100px;
  margin-top: 30px;
}
</style>
