<template>
  <div style="background-color: #fff">
    <div class="toppicd">
      <img src="../../../../Newimg/fuwuruzhu.jpg" alt="" />
    </div>
    <div class="pagebody">
      <div class="enter">
        <mytitle>服务商入驻流程</mytitle>
        <ul class="enter_cont">
          <li v-for="(item, index) in procedure" :key="index">
            <div class="img">
              <img :src="item.src" alt="" />
              <span>
                {{ item.text }}
              </span>
            </div>
            <div class="arrow">
              <img src="@/assets/image/enter/arrow.png" alt="" />
            </div>
          </li>
        </ul>
        <div class="application" @click="shenqingruzhu">申请入驻</div>
      </div>
    </div>
    <div class="pagebody" style="border-top: 90px solid #f7f7f7">
      <div class="enter">
        <mytitle>服务商入驻条件</mytitle>
        <div class="condition">
          <div
            class="condition-item"
            v-for="(item, index) in condition"
            :key="index"
          >
            <div class="condition-title">
              <span>{{ item.number }}</span>
              {{ item.title }}
            </div>
            <div class="condition-content" v-html="item.content"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagebody" style="border-top: 90px solid #f7f7f7">
      <div class="enter">
        <mytitle>平台优势</mytitle>
        <div class="advantage">
          <div
            class="advantage-item"
            v-for="(item, index) in advantage"
            :key="index"
          >
            <div class="advantage-logo">
              <img :src="item.src" alt="" />
            </div>
            <div class="advantage-title">
              {{ item.title }}
            </div>
            <div class="advantage-content" v-html="item.content"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagebody" style="border-top: 90px solid #f7f7f7">
      <div class="enter">
        <mytitle>平台优势</mytitle>
        <div class="cooperation">
          <div
            class="cooperation-item"
            v-for="(item, index) in cooperation"
            :key="index"
          >
            <div class="cooperation-logo">
              <img :src="item.src" alt="" />
            </div>
            <div class="cooperation-text">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Topic from "@/components/Topics.vue";
import mytitle from "@/components/title.vue";
import { applyQiKan } from "@/API/homepage/policy.js";
export default {
  components: {
    Topic,
    mytitle,
  },
  data() {
    return {
      //入驻流程数据
      procedure: [
        // { "src": require('@/assets/image/enter/register.png'), "text": "注册账号" },
        { src: require("@/assets/image/enter/goutong.jpg"), text: "意向沟通" },
        { src: require("@/assets/image/enter/renzheng.jpg"), text: "实名认证" },
        { src: require("@/assets/image/enter/shenhe.jpg"), text: "平台审核" },
        { src: require("@/assets/image/enter/xieyi.jpg"), text: "签署协议" },
        {
          src: require("@/assets/image/enter/chenggong.jpg"),
          text: "入驻成功",
        },
      ],
      cooperation: [
        { src: require("@/assets/image/enter/shuiping.jpg"), text: "提升专业水平" },
        { src: require("@/assets/image/enter/ziyuan.jpg"), text: "积累行业资源" },
        { src: require("@/assets/image/enter/shouru.jpg"), text: "可观的经济收入" },
      ],
      advantage: [
        {
          src: require("@/assets/image/enter/pingtai.jpg"),
          title: "正规平台 ",
          content: "九项互联网经营资质，国家相关部门全程监督，安全可靠",
        },
        {
          src: require("@/assets/image/enter/tuandui.jpg"),
          title: "八年专业服务团队",
          content: "深耕行业八年，服务客户10万+，瞬间引爆行业价值！",
        },
        {
          src: require("@/assets/image/enter/gongying.jpg"),
          title: "数千家期刊合作共赢",
          content: "严格把控论文质量，节约杂志社编辑审稿时间，减轻杂志社负担！",
        },
        {
          src: require("@/assets/image/enter/jishu.jpg"),
          title: "互联网+AI+区块链技术",
          content: "打造最智能的投发稿平台、深耕互联网+AI智能+区块链服务商。",
        },
      ],
      condition: [
        {
          number: "01",
          title: "行业相关",
          content:
            "需要从事论文，职称相关出版行业的人员，从事教育培训、担任事业单位、国企、上市公司、人事部工作人员、职改办工作人员等，具有从业的相关资质。",
        },
        {
          number: "02",
          title: "实名认证",
          content:
            "个人用户：在实名认证申请页面填写真实姓名，提供身份信息。<br/><br/>企业、社会组织用户：上传营业执照副本、法人的身份证照片。",
        },
        {
          number: "03",
          title: "缴纳保证金",
          content: "按要求提供相关资料并缴纳保证金后，申请开通服务商账户。",
        },
        {
          number: "04",
          title: "服务商等级",
          content: "普通服务商<br/><br/>中级服务商<br/><br/>高级服务商",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    shenqingruzhu(){
      window._MICHAT.startChat();
    },
  },
};
</script>

<style scoped="scoped">
.cooperation {
  display: flex;
  margin: 0 auto;
  margin-top: 60px;
  width: 80%;
  justify-content: space-around;
}
.cooperation-logo{
  width: 130px;
  height: 130px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 20px 0 rgba(100, 100, 100, 0.1);
}
.cooperation-text{
  text-align: center;
  font-size: 22px;
  color: #535353;
  margin-top: 42px;
}
.cooperation-item {
  transition: all 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.advantage {
  display: flex;
  margin: 0 auto;
  margin-top: 60px;
  width: 80%;
  justify-content: space-between;
}
.advantage-item {
  width: 24%;
  height: 180px;
  border-radius: 26px;
  box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.1);
  transition: all 0.2s;
  box-sizing: border-box;
  padding: 30px 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.advantage-item:hover {
  box-shadow: 0 0 20px 0 rgba(100, 100, 100, 0.2);
}
.advantage-title {
  font-size: 16px;
  line-height: 22px;
  color: #535353;
}
.advantage-content {
  color: #757575;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-top: 12px;
}
.condition {
  display: flex;
  margin: 0 auto;
  margin-top: 60px;
  width: 80%;
  justify-content: space-between;
}
.condition-item {
  width: 24%;
  height: 230px;
  border-radius: 26px;
  box-shadow: 0 0 10px 0 rgba(100, 100, 100, 0.1);
  transition: all 0.2s;
  box-sizing: border-box;
  padding: 30px 36px;
}
.condition-item:hover {
  box-shadow: 0 0 20px 0 rgba(100, 100, 100, 0.2);
}
.condition-title {
  margin-bottom: 20px;
}
.condition-title span {
  font-size: 24px;
  font-weight: bold;
  color: red;
}
.condition-content {
  color: #757575;
  font-size: 14px;
  line-height: 20px;
}
.apply {
  width: 100% !important;
  padding-bottom: 30px;
  /* background: url(@/../Newimg/beijing.jpg)round; */
}
.enter {
  padding: 26px 0;
  padding-bottom: 60px;
}
.enter_cont {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 24px;
  margin-top: 40px;
  width: 80%;
}
.enter_cont li {
  display: flex;
}

.enter_cont li .arrow img {
  margin: 0 26px;
  margin-top: 29px;
  width: 140px;
}
.enter_cont li .img {
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 16px;
}
.img img {
  margin-bottom: 14px;
  display: block;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  box-shadow: 0 0 20px 0px rgba(100, 100, 100, 0.1);
}
.enter_cont li:last-child .arrow {
  display: none;
}
.application {
  cursor: pointer;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 30px;
  width: 250px;
  height: 58px;
  background: #ffa328;
  border-radius: 29px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  line-height: 58px;
}
.application:hover {
  box-shadow: 0 0 20px 0 rgba(255, 163, 40, 0.2);
}
.application a {
  color: #fff;
}
.bg {
  background-color: #f7f7f7;
  padding: 20px;
  padding-bottom: 40px;
}
.toppicd {
  width: 100%;
}
.toppicd img {
  width: 100%;
}
</style>