<template>
	<div style="padding: 20px;  background-color: #fff;">
			<p class="dl" v-for="(item,index) in textlist" :key="index" v-html='item.content'></p>
	</div>
</template>

<script>
  import { selectSubmissionProcess } from "../../../API/footer.js"
  export default {
	
		data() {
			return {
		
        textlist:[],
			}
		},
		mounted() {
      this.copyrightNoticelist()
		},
		methods: {
			copyrightNoticelist() {
			  selectSubmissionProcess().then(res =>{
       console.log(res);
          this.textlist = res.data.data
          console.log( this.textlist);
        })

			},
    }
  }
</script>

<style>
	.dl{
		margin:5px 0;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		line-height: 26px;
	}
</style>
