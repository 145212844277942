<template>
  <div class="article">
    <div class="article_cont">
      <div class="w">
        <div style="display: flex">
          <div class="sorttitile">学术分类</div>
          <el-collapse accordion v-model="activeNames">
            <el-collapse-item name="1">
              <template slot="title">
                <div class="qkfl">
                  <span
                    @click="typebx"
                    :class="xstypenum == 10000 ? 'active' : ''"
                    >不限</span
                  >
                  <span
                    v-for="(item, index) in type.slice(0, 15)"
                    :class="xstypenum == item.id ? 'active' : ''"
                    :key="index"
                    @click="selecttype(item.id)"
                    >{{ item.types }}</span
                  >
                </div>
              </template>
              <div class="qkfl">
                <span
                  v-for="(item, index) in type.slice(15, type.length)"
                  :class="xstypenum == item.id ? 'active' : ''"
                  :key="index"
                  @click="selecttype(item.id)"
                  >{{ item.types }}</span
                >
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div style="display: flex">
          <div class="sorttitile">期刊收录</div>
          <el-collapse accordion>
            <el-collapse-item>
              <template slot="title">
                <div class="qkfl">
                  <span
                    @click="inclucebx"
                    :class="qkincludenum == 10000 ? 'active' : ''"
                    >不限</span
                  >
                  <span
                    v-for="(item, index) in include.slice(0, 8)"
                    @click="selectinclude(item.id, item.number)"
                    :key="index"
                    :class="qkincludenum == item.id ? 'active' : ''"
                    >{{ item.include }}</span
                  >
                </div>
              </template>
              <div class="qkfl">
                <span
                  v-for="(item, index) in include.slice(8, include.length)"
                  :key="index"
                  @click="selectinclude(item.id, item.number)"
                  :class="qkincludenum == item.id ? 'active' : ''"
                  >{{ item.include }}</span
                >
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div style="display: flex">
          <div class="sorttitile">发行周期</div>
          <el-collapse accordion>
            <el-collapse-item>
              <template slot="title">
                <div class="qkfl">
                  <span
                    @click="cyclebx"
                    :class="fxcyclenum == 10000 ? 'active' : ''"
                    >不限</span
                  >
                  <span
                    v-for="(item, index) in publishcycle"
                    :class="fxcyclenum == index ? 'active' : ''"
                    :key="index"
                    @click="selectcycle(item, index)"
                    >{{ item }}</span
                  >
                </div>
              </template>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div style="display: flex">
          <div class="sorttitile">出版地区</div>
          <el-collapse accordion>
            <el-collapse-item>
              <template slot="title">
                <div class="qkfl">
                  <span
                    @click="areabx()"
                    :class="cbareanum == 10000 ? 'active' : ''"
                    >不限</span
                  >
                  <span
                    v-for="(item, index) in publishing.slice(0, 18)"
                    :class="cbareanum == item.id ? 'active' : ''"
                    :key="index"
                    @click="selectarea(item.area, item.id)"
                    >{{ item.area }}</span
                  >
                </div>
              </template>
              <div class="qkfl">
                <span
                  v-for="(item, index) in publishing.slice(
                    18,
                    publishing.length
                  )"
                  :class="cbareanum == item.id ? 'active' : ''"
                  :key="index"
                  @click="selectarea(item.area, item.id)"
                  >{{ item.area }}</span
                >
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div style="display: flex">
          <div class="sorttitile">审校时间</div>
          <el-collapse accordion>
            <el-collapse-item>
              <template slot="title">
                <div class="qkfl">
                  <span
                    @click="timebx()"
                    :class="xstimenum == 10000 ? 'active' : ''"
                    >不限</span
                  >
                  <span
                    v-for="(item, index) in checktime"
                    :class="xstimenum == index ? 'active' : ''"
                    :key="index"
                    @click="selecttime(item, index)"
                    >{{ item }}</span
                  >
                </div>
              </template>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div style="display: flex">
          <div class="sorttitile">影响因子</div>
          <el-collapse accordion>
            <el-collapse-item>
              <template slot="title">
                <div class="qkfl">
                  <span
                    @click="timebxs()"
                    :class="yxtiemnum == 10000 ? 'active' : ''"
                    >不限</span
                  >
                  <span
                    v-for="(item, index) in listdatalist"
                    :class="yxtiemnum == index ? 'active' : ''"
                    :key="index"
                    @click="selecttimes(item.id, index)"
                    >{{ item.num }}</span
                  >
                </div>
              </template>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div class="choose">
          <div @click="baseontime" :class="baseon == 0 ? 'baseon' : ''">
            按时间
          </div>
          <div @click="baseoncomment" :class="baseon == 1 ? 'baseon' : ''">
            按评论
          </div>
          <div @click="baseonpop" :class="baseon == 2 ? 'baseon' : ''">
            按人气
          </div>
        </div>
        <artlist :artlist="artlist"></artlist>
      </div>
    </div>
    <div class="page">
      <el-pagination
        class="fy"
        small
        @size-change="handleSizeChange"
        :page-size="pagesize"
        :page-sizes="[10, 20, 30]"
        layout="total, sizes, prev, pager, next, jumper"
        @current-change="current_change"
        :total="total"
        background
      >
      </el-pagination>
    </div>
  </div>
</template>
<!--<script src="../public/layui/layui.js"></script>-->
<script>
import artlist from "@/components/Artlist.vue";
import {
  Academic,
  Include,
  Literature,
} from "../../API/homepage/document/document.js";
import { searchdoc } from "../../API/homepage/policy.js";
export default {
  name: "Article",
  components: {
    artlist,
  },
  data() {
    return {
      activeNames: ["1"],
      total: 0, //默认数据总数
      pagesize: 10, //每页的数据条数
      currentPage: 1, //默认开始页面
      type: [], //学术分类
      include: [], //收录
      publishing: [
        { area: "天津", id: 1 },
        { area: "北京", id: 2 },
        { area: "上海", id: 3 },
        { area: "甘肃", id: 4 },
        { area: "重庆", id: 5 },
        { area: "青海", id: 6 },
        { area: "安徽", id: 7 },
        { area: "湖南", id: 8 },
        { area: "河南", id: 9 },
        { area: "辽宁", id: 10 },
        { area: "贵州", id: 11 },
        { area: "上海", id: 12 },
        { area: "广东", id: 13 },
        { area: "浙江", id: 14 },
        { area: "海南", id: 15 },
        { area: "江苏", id: 16 },
        { area: "山东", id: 17 },
        { area: "山西", id: 18 },
        { area: "湖北", id: 19 },
        { area: "广西", id: 20 },
        { area: "福建", id: 21 },
        { area: "河北", id: 22 },
        { area: "内蒙古", id: 23 },
        { area: "宁夏", id: 24 },
        { area: "江西", id: 25 },
        { area: "吉林", id: 26 },
        { area: "云南", id: 27 },
        { area: "新疆", id: 28 },
        { area: "山西", id: 29 },
        { area: "西藏", id: 30 },
        { area: "黑龙江", id: 31 },
      ], //出版地区
      publishcycle: [
        "周二刊",
        "周刊",
        "半月刊",
        "月刊",
        "双月刊",
        "季刊",
        "半年刊",
        "年刊",
        "旬刊",
      ], //发行周期
      checktime: ["1个月以内", "1-3个月", "3-6个月", "6-9个月", "1年以上"], //审校时间
      //影响因子
      listdatalist: [
        { num: "0-0.5", id: "1" },
        { num: "0.5-1.0", id: "2" },
        { num: "1.0-2.0", id: "3" },
        { num: "2.1-3.0", id: "4" },
        { num: "3.1-4.0", id: "5" },
        { num: "3.1-4.0", id: "6" },
        { num: "4.1-5.0", id: "5" },
        { num: "5.1-6.0", id: "6" },
        { num: "6.1-7.0", id: "7" },
        { num: "7.1-8.0", id: "8" },
        { num: "8.1-9.0", id: "9" },
        { num: "9.0-9.9", id: "10" },
      ],
      xstype: "", //学术分类
      qkinclude: "", //期刊受录
      fxcycle: "", //发行周期
      cbarea: "", //出版地区
      xstime: "", //审时间
      yxnum: "", //影响因子
      xstypenum: 10000, //学术分类
      qkincludenum: 10000, //期刊受录
      fxcyclenum: 10000, //发行周期
      cbareanum: 10000, //出版地区
      xstimenum: 10000, //审时间
      yxtiemnum: 10000, // 影响因子
      baseon: 0,
      //书籍列表数据
      artlist: [],
    };
  },
  methods: {
    // 分页
    current_change: function (currentPage) {
      this.currentPage = currentPage;

      this.myLiterature();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pagesize = val;

      this.myLiterature();
    }, //学术分类接口
    myAcademic() {
      Academic().then((res) => {
        console.log(res);
        this.type = res.data.data;
      });
    },
    myInclude() {
      //期刊收录接口
      Include().then((res) => {
        console.log(res);
        this.include = res.data.data;
      });
    },
    typebx() {
      //点击不限的5个方法
      event.stopPropagation();
      this.xstype = "";
      this.xstypenum = 10000;
      this.myLiterature();
    },
    inclucebx() {
      event.stopPropagation();
      this.qkinclude = "";
      this.qkincludenum = 10000;
      this.myLiterature();
    },
    cyclebx() {
      event.stopPropagation();
      this.fxcycle = "";
      this.fxcyclenum = 10000;
      this.myLiterature();
    },
    areabx() {
      event.stopPropagation();
      this.cbarea = "";
      this.cbareanum = 10000;
      this.myLiterature();
    },
    timebx() {
      event.stopPropagation();
      this.xstime = "";
      this.xstimenum = 10000;
      this.myLiterature();
    },
    timebxs() {
      //点击不限的5个方法
      event.stopPropagation();
      this.yxnum = "";
      this.yxtiemnum = 10000;
      this.myLiterature();
    },
    selecttype(e) {
      //选择类型后的5个方法
      event.stopPropagation();
      this.xstype = e;
      this.xstypenum = e;
      this.myLiterature();
    },
    selectinclude(id, num) {
      event.stopPropagation();
      this.qkinclude = num;
      this.qkincludenum = id;
      this.myLiterature();
    },
    selectcycle(id, num) {
      event.stopPropagation();
      this.fxcycle = id;
      this.fxcyclenum = num;
      this.myLiterature();
    },
    selectarea(id, num) {
      event.stopPropagation();
      this.cbarea = id;
      this.cbareanum = num;
      this.myLiterature();
    },
    selecttime(id, num) {
      event.stopPropagation();
      this.xstime = id;
      this.xstimenum = num;
      this.myLiterature();
    },
    selecttimes(id, num) {
      event.stopPropagation();
      this.yxnum = id;
      this.yxtiemnum = num;
      this.myLiterature();
    },
    baseontime() {
      //依据的三个方法
      this.baseon = 0;
      this.myLiterature();
    },
    baseoncomment() {
      this.baseon = 1;
      this.myLiterature();
    },
    baseonpop() {
      this.baseon = 2;
      this.myLiterature();
    },

    myLiterature() {
      //文献分类列表接口
      sessionStorage.setItem("fxcyclenum", this.fxcyclenum);
      sessionStorage.setItem("cbareanum", this.cbareanum);
      sessionStorage.setItem("xstimenum", this.xstimenum);
      sessionStorage.setItem("yxtiemnum", this.yxtiemnum);
      sessionStorage.setItem("qkincludenum", this.qkincludenum);
      console.log('更改筛选条件')
      Literature(
        this.currentPage,
        this.pagesize,
        this.xstype,
        this.qkinclude,
        this.fxcycle,
        this.cbarea,
        this.xstime,
        this.yxnum,
        this.baseon
      ).then((res) => {
        this.artlist = res.data.data;
        this.total = res.data.total;
      });
    },
    selech() {
      if (this.$route.query.name) {
        searchdoc(
          this.$route.query.name,
          this.pagesize,
          this.currentPage,
          this.$route.query.id
        ).then((res) => {
          console.log(res);
          this.artlist = res.data.data;
          this.total = res.data.total;
        });
      }
    },
  },
  mounted() {
    if (this.$route.query.acd) {
      this.xstypenum = this.$route.query.acd;
      this.xstype = this.$route.query.acd;
    }
    console.log("获取筛选缓存");
    this.fxcyclenum = sessionStorage.getItem("fxcyclenum");
    this.cbareanum = sessionStorage.getItem("cbareanum");
    this.xstimenum = sessionStorage.getItem("xstimenum");
    this.yxtiemnum = sessionStorage.getItem("yxtiemnum");
    this.qkincludenum = sessionStorage.getItem("qkincludenum");
    this.myAcademic();
    this.myInclude();
    this.myLiterature();
  },
  created() {
    this.selech();
  },
};
//总页数大于页码总数
</script>

<style scoped>
.article {
  background-color: #fff;
  margin-top: 5px;
  padding: 20px 0;
}
.article_cont {
  width: 100%;
}
.sort {
  position: relative;
  display: flex;
  border-top: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
}
.sort .sort_title {
  width: 10%;
  font-size: 14px;
  background: #f2f2f2;
  text-align: center;
  line-height: 35px;
}
.qkfl {
  padding: 20px;
  box-sizing: border-box;
  width: 950px;
}
.qkfl span {
  display: inline-block;
  padding: 0 5px;
  text-align: center;
  cursor: pointer;
  margin: 0 3px;
}
.sort .change {
  width: 10%;
  position: absolute;
  right: 0;
  font-size: 14px;
  background: #fff;
  text-align: center;
  line-height: 35px;
  cursor: pointer;
}
.change img {
  width: 12px;
}
.sort_cont {
  width: 80%;
  position: absolute;
  left: 10%;
  line-height: 35px;
  border: 1px solid #f2f2f2;
  border-top: 0;
  border-right: 0;
  font-size: 14px;
  color: #333333;
}
.qkfl {
  padding: 20px;
  box-sizing: border-box;
  width: 982px;
}
.qkfl span {
  display: inline-block;
  padding: 0 5px;
  text-align: center;
  cursor: pointer;
  margin: 0 3px;
}
.sorttitile {
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-size: 14px;
  color: #666;
}
.sort_cont .squ {
  font-size: 14px;
  cursor: pointer;
  float: left;
  line-height: 35px;
}
.sort_cont .squ i:hover {
  background: #ff4318;
  border-radius: 4px;
  color: #fff;
}
.squ i {
  padding: 0 5px;
}
.article_cont ul {
  margin-top: 20px;
}
.active {
  color: #fff;
  padding: 0 5px;
  background: #ff4318;
  border-radius: 4px;
  height: 20px;
  line-height: 20px;
}
.choose {
  margin-top: 20px;
  height: 50px;
  background: #f2f2f2;
  border-radius: 4px;
  line-height: 50px;
}
.choose div {
  position: relative;
  float: left;
  font-size: 14px;
  margin: 0 20px;
  cursor: pointer;
  color: #999;
}
.choose div::after {
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: 20px;
  right: -20px;
  content: "";
  border: 6px solid #999;
  border-left: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid transparent;
}
.choose .baseon {
  position: relative;
  float: left;
  font-size: 14px;
  margin: 0 20px;
  cursor: pointer;
  color: #000000;
}
.choose .baseon::after {
  width: 0;
  height: 0;
  display: block;
  position: absolute;
  top: 20px;
  right: -20px;
  content: "";
  border: 6px solid #000;
  border-left: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid transparent;
}
.page {
  height: 100px;
  width: 420px;
  margin: 0 auto;
  line-height: 100px;
  margin-top: 30px;
}
</style>