<template>
    <div>
        <Topic>
            <!-- <div slot="topicTitle" class="first">优质期刊投稿</div>
            <div slot="topicSqu" class="last">全网数字化大数据</div> -->
        </Topic>
        <div class="bg" v-for="item,index in magzine" :style="{backgroundColor:index%2==0?'#F6F6F6':'#FFF'}">
            <div class="w mcont">
                <div class="magzinecl">
                    <mtitle>{{item.them}}</mtitle>
                    <div class="magpic">
                        <div v-for="val in item.cont" @click="showmagzinedetail(val.qikan_id,val.qikantype_id)">
                            <img :src='val.cover' alt=""/>
                            <span>{{val.qk_name}}</span>
                        </div>
                    </div>
                    <div class="more" @click="seemore(item.them)">查看更多</div>
                </div>
            </div>
        </div>

        <div class="box">
            <!-- <ul class="w num">
                <li><span>3200</span>本优选期刊</li>
                <li><span>1000+</span>战略合作期刊</li>
                <li><span>100000+</span>录用数据</li>
                <li><span>10000000</span>条数据分析</li>
            </ul> -->
        </div>

    </div>

</template>

<script>
    import {
        Academic,
        Include,
        Classification // 相关期刊
    } from '../../API/homepage/document/document.js'
    import Topic from '@/components/Topics.vue'
    import mtitle from '@/components/title.vue'

    export default {
        components: {
            Topic,
            mtitle
        },
        data() {
            return {
                //期刊数据
                magzine: [{
                    "them": "推荐期刊",
                    "cont": [{
                        "cover": "/kandashi/7f3a5f6430bb42a79fe4a0dddf1451dc.jpeg",
                        "qk_name": "桃花源记心得",
                        "qikan_id": 1
                    }

                    ],
                },
                    {
                        "them": "教育期刊",
                        "cont": [],

                    },

                    {
                        "them": "经济期刊",
                        "cont": [],

                    }
                    // {
                    // 	"them": "金融期刊",
                    // 	"cont": [
                    //
                    // 	],
                    // }
                ],
                bgcolor: "#fff",


            }
        },
        mounted() {
            this.susumeiLiterature()
            this.economyLiterature()
            this.educationLiterature()
            this.moneyLiterature()

        },
        methods: {
            seemore(cc) {
               // alert(cc);
                this.$router.push('/classification?now='+cc)
            },
            susumeiLiterature() { //推荐期刊接口
                Classification(1, 10, '', '', '', '', '', '').then(res => {
                    this.magzine[0].cont = res.data.data
                    for (var i = 0; i < this.magzine[0].cont.length; i++) {
                        this.magzine[0].cont[i].cover = this.URL + this.magzine[0].cont[i].cover
                    }
                })
            },
            educationLiterature() { //教育期刊接口
                Classification(1, 10, 1, '', '', '', '', '').then(res => {
                    console.log(res, "教育期刊数据=======================================")
                    this.magzine[1].cont = res.data.data
                    for (var i = 0; i < this.magzine[1].cont.length; i++) {
                        this.magzine[1].cont[i].cover = this.URL + this.magzine[1].cont[i].cover
                    }
                })
            },
            economyLiterature() { //经济期刊接口
                Classification(1, 10, 3, '', '', '', '', '').then(res => {
                    console.log(res)
                    this.magzine[2].cont = res.data.data
                    for (var i = 0; i < this.magzine[2].cont.length; i++) {
                        this.magzine[2].cont[i].cover = this.URL + this.magzine[2].cont[i].cover
                    }

                })
            },
            moneyLiterature() { //金融期刊接口
                Classification(1, 10, 4, '', '', '', '', '').then(res => {
                    console.log(res)
                    this.magzine[3].cont = res.data.data
                    for (var i = 0; i < this.magzine[3].cont.length; i++) {
                        this.magzine[3].cont[i].cover = this.URL + this.magzine[3].cont[i].cover
                    }
                })
            },
            showmagzinedetail(e, s) {
                this.$router.push({
                    path: '/postingdetails',
                    query: {
                        mid: e,
                        qkId: s
                    }
                })
            }
        }


    }
</script>

<style scoped>
    .magzinecl {
        padding-bottom: 30px;
    }

    .magzinecl .mthem {
        text-align: center;
        height: 42px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #141414;
        line-height: 42px;
        margin-bottom: 20px;


    }

    .mcont {
        margin-top: 30px;
    }

    .magpic {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .magpic div {
        text-align: center;
        width: 18%;
        cursor: pointer;
        margin-bottom: 10px;
    }

    .magpic div span {
        display: block;
        margin: 8px 0;
    }

    .magpic div img {
        width: 180px;
        height: 220px;
    }

    .more {
        width: 100px;
        height: 40px;
        margin: 0 auto;
        border-radius: 4px;
        border: 1px solid #FF1918;
        text-align: center;
        line-height: 40px;
        font-size: 14px;
        color: #FF1918;
        cursor: pointer;
    }

    .box {

        width: 100%;
        height: 200px;
        background: #000000;
        opacity: 0.7;
        color: #fff;
        background: url(../../../Newimg/数字.jpg) round;

    }

    .num {
        height: 100%;
        display: flex;
        justify-content: space-around;
    }

    .num li {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
</style>
