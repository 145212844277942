import request from '@/utils/request'
import qs from 'qs'
// 公司简介
export function companyIntroducelist (state) {
  let data = {
    state
  }
  data = qs.stringify(data)
  return request({
    url: '/platform/companyIntroduce',
    method: 'post',
    data
  })
}
// 工作环境
export function selectWorSpaceById (state) {
  let data = {
    state
  }
  data = qs.stringify(data)
  return request({
    url: '/platform/selectWorSpaceById',
    method: 'post',
    data
  })
}

// 团建活动
export function selectActivity (state) {
  let data = {
    state
  }
  data = qs.stringify(data)
  return request({
    url: '/platform/selectActivity',
    method: 'post',
    data
  })
}

// 荣耀资质
export function selectGlory (state) {
  let data = {
    state
  }
  data = qs.stringify(data)
  return request({
    url: '/platform/selectGlory',
    method: 'post',
    data
  })
}
// 公司文化
export function selectCompanyCulture () {
  let data = {
    
  }
  data = qs.stringify(data)
  return request({
    url: '/platform/selectCompanyCulture',
    method: 'post',
    data
  })
}
// 关于我们
export function companyIntroduce () {
  let data = {
    
  }
  data = qs.stringify(data)
  return request({
    url: '/aboutUs/companyIntroduce',
    method: 'post',
    data
  })
}
// 年份筛选
export function yearList () {
  let data = {
    
  }
  data = qs.stringify(data)
  return request({
    url: '/companyDynamic/yearList',
    method: 'post',
    data
  })
}
// 年份筛选
export function listCompanyDynamic (limit,page,year) {
  let data = {
    limit,page,year
  }
  data = qs.stringify(data)
  return request({
    url: '/companyDynamic/listCompanyDynamic',
    method: 'post',
    data
  })
}
// 年份筛选
export function listCompanyDynamicMain (limit,page,year,isType) {
  let data = {
    limit,page,year,isType
  }
  data = qs.stringify(data)
  return request({
    url: '/companyDynamic/listCompanyDynamic',
    method: 'post',
    data
  })
}
export function detailCompanyDynamic (id) {
  let data = {
    id
  }
  data = qs.stringify(data)
  return request({
    url: '/companyDynamic/detailCompanyDynamic',
    method: 'post',
    data
  })
}

// 市运维
export function selectCityYunwei () {
  let data = {
    
  }
  data = qs.stringify(data)
  return request({
    url: '/platform/selectCityYunwei',
    method: 'post',
    data
  })
}
// 省运维
export function selectProvinceYunWei () {
  let data = {
    
  }
  data = qs.stringify(data)
  return request({
    url: '/platform/selectProvinceYunWei',
    method: 'post',
    data
  })
}
// 版权声明
export function copyrightNotice () {
  let data = {
    
  }
  data = qs.stringify(data)
  return request({
    url: '/home/copyrightNotice',
    method: 'post',
    data
  })
}
//加入我们
export function joinUs () {
  let data = {
    
  }
  data = qs.stringify(data)
  return request({
    url: '/home/joinUs',
    method: 'post',
    data
  })
}
// 智能投稿
export function selectSubmissionProcess () {
  let data = {
    
  }
  data = qs.stringify(data)
  return request({
    url: '/platform/selectSubmissionProcess',
    method: 'post',
    data
  })
}
// 常见问题
export function commonQuestion () {
  let data = {
    
  }
  data = qs.stringify(data)
  return request({
    url: '/home/commonQuestion',
    method: 'post',
    data
  })
}

// 联系我们
export function selectContact_us () {
  let data = {
    
  }
  data = qs.stringify(data)
  return request({
    url: '/home/selectContact_us',
    method: 'post',
    data
  })
}
// 服务流程
export function service_process () {
  let data = {
    
  }
  data = qs.stringify(data)
  return request({
    url: '/home/service_process',
    method: 'post',
    data
  })
}
// 期刊入驻
export function selectQiKanSettleIn () {
  let data = {
    
  }
  data = qs.stringify(data)
  return request({
    url: '/platform/selectQiKanSettleIn',
    method: 'post',
    data
  })
}
// 服务商申请入驻
export function selectFwsSettleIn () {
  let data = {
    
  }
  data = qs.stringify(data)
  return request({
    url: '/platform/selectFwsSettleIn',
    method: 'post',
    data
  })
}